import React, { useState, useEffect } from "react";
import { InputLabelAlert, InputAlert } from "../../layout";
import { formatCurrency, Aguarde, width } from "../../../helpers/general";
import { bancos } from "../../../helpers/bancos";
import { sendData, getData } from "../../../helpers/auth";
import { uriFst } from "../../../helpers/config";
import { bancosCNPJ } from "../../../helpers/bancos";
import Select from 'react-select'

const customStyles = {
  control: () => ({
    width: 250,
  }),
}

const Form = ({ ...props }) => {
  let _disabled =
    !props.portabilidade && (props.data.automatico || props.automatico);

  return (
    <>
      {props.simulacao && (
        <Aguarde legenda={"Aguarde, simulando proposta..."} />
      )}
      {!props.simulacao && props.carregando && (
        <Aguarde legenda={"Aguarde, carregando dados..."} />
      )}

      <div className="form">
        <>
          <div className="row">
            <div className="row form-group col-md-12">
              <div className="form-group col-md-5">
                <InputLabelAlert
                  type="tel"
                  label="CPF"
                  id="cpf"
                  value={props.data.cpf}
                  handle={props.handleCpf}
                  warning={props.warning.cpf}
                  autoComplete="off"
                  length="14"
                  msgErro={"É necessário informar o CPF"}
                  focus={true}
                  onBlur={props.CarregarDados}
                  onContextMenu={(e) => e.preventDefault()}
                />

                <InputAlert
                  display={props.msg && props.msg.cpf}
                  messages={"CPF inválido."}
                />
              </div>
              <div className="form-group col-md-7">
                <InputLabelAlert
                  type="tel"
                  label="Benefício/Matricula"
                  id="matricula"
                  value={props.data.matricula}
                  handle={props.change}
                  warning={props.warning.matricula}
                  autoComplete="off"
                  length="20"
                  msgErro={"É necessário informar o Benefício"}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <InputAlert
                  display={props.msg && props.msg.matricula}
                  messages={"Benefício/Matrícula inválida."}
                />
              </div>

            </div>

            <div className="row form-group col-md-12">
              <div className="form-group col-md-5">
                <label htmlFor="convenio_id" className="no-margin">
                  Convênio
                </label>
                <select
                  defaultValue={props.data.convenio_id}
                  onChange={(e) =>
                    props.handleConvenio(e.currentTarget.value)
                  }
                  name="convenio_id"
                  id="convenio_id"
                  style={{
                    borderColor: props.warning.banco ? "red" : "",
                    textAlign: "left",
                  }}
                  className="form-control custom-select"
                >
                  {!props.convenioList && <option>Carregando...</option>}
                  {props.convenioList ? (
                    props.convenioList.map((convenio, index) => (
                      <option
                        key={index}
                        title={convenio.sigla}
                        value={convenio.id}
                      >
                        {convenio.nome.toUpperCase()}
                      </option>
                    ))
                  ) : (
                    <option>Carregando...</option>
                  )}
                </select>

                <InputAlert
                  display={props?.warning?.convenio}
                  messages={"É necessário informar o Convênio."}
                />
              </div>

              <div className="form-group col-md-7">
                <label htmlFor="tabela_id" className="no-margin">
                  Tabela
                </label>
                <select
                  defaultValue={props.data.tabela_id}
                  onChange={(e) =>
                    props.handleTabela(e.currentTarget.value)
                  }
                  name="tabela_id"
                  id="tabela_id"
                  style={{
                    borderColor: props.warning.banco ? "red" : "",
                    textAlign: "left",
                  }}
                  className="form-control custom-select"
                >
                  {!props.tabelaList && <option>Carregando...</option>}
                  {props.tabelaList ? (
                    props.tabelaList.map((tabela, index) => (
                      <option
                        key={`tabela_id-${tabela.id}`}
                        title={tabela.nome}
                        value={tabela.id}
                      >
                        {tabela.nome}
                      </option>
                    ))
                  ) : (
                    <option>Carregando...</option>
                  )}
                </select>
                <InputAlert
                  display={props?.warning?.tabela_id}
                  messages={"É necessário informar a Tabela."}
                />
              </div>

            </div>
            <div className="row form-group col-md-12">
              <div className="form-group col-md-4">
                <label htmlFor="tipo_simulacao" className="no-margin">
                  Tipo de Simulação
                </label>
                <select
                  defaultValue={props.data.tipo_simulacao}
                  onChange={props.change
                  }
                  name="tipo_simulacao"
                  id="tipo_simulacao"
                  style={{
                    borderColor: props.warning.banco ? "red" : "",
                    textAlign: "left",
                  }}
                  className="form-control custom-select"
                >
                  <option
                    key={1}
                    title={"Vlr Parcela"}
                    value={"vlr_parcela"}
                  >
                    Valor Parcela
                  </option>
                  <option
                    key={1}
                    title={"Vlr Proposta"}
                    value={"vlr_proposta"}
                  >
                    Valor Proposta
                  </option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="prazo" className="no-margin">
                  Prazo
                </label>
                <select
                  id="prazo"
                  name="prazo"
                  defaultValue={props.data.prazo}
                  onChange={(e) => props.handlePrazo(e.currentTarget.value)}
                  style={{ textAlign: "left" }}
                  className="form-control custom-select"
                >
                  {props.prazoSteps &&
                    props.prazoSteps.map((prazo, i) => (
                      <option
                        key={`${prazo.prazo}${i}}`}
                        value={prazo.prazo}
                      >
                        {prazo.prazo}
                      </option>
                    ))}
                </select>
                <InputAlert
                  display={props?.warning?.prazo}
                  messages={"É necessário informar o Prazo."}
                />
              </div>

              <div className="form-group col-md-4">
                <InputLabelAlert
                  type="tel"
                  label="Valor"
                  id="valor"
                  // cifrao={true}
                  value={props.data.valor}
                  step="0.010"
                  handle={props.change}
                  warning={props.warning.valor}
                  onChange={props.change}
                  msgErro={"É necessário informar o Valor"}
                  focus={false}
                />
                <InputAlert
                  display={props?.warning?.valor}
                  messages={"É necessário informar o Valor."}
                />
              </div>
            </div>

          </div>
        </>


      </div>
    </>
  );
};

export default Form;
