import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { uriList } from "../../../../helpers/config";
import { sendData } from "../../../../helpers/auth";
import ModalContent from "../../../layout/ModalContent";
import { bancoFornecedor } from "../../../../helpers/auth";
import { connect } from "react-redux";
import * as moment from "moment";
import { width } from "../../../../helpers/general";

const PropostaStatusDetalhes = ({ ...props }) => {
  const [carregando, setCarregando] = useState(false);
  const [data, setData] = useState(false);

  function handleClose(e, close) {
    if (e) e.preventDefault();
    if (props.onCloseEv) props.onCloseEv(close);
  }

  useEffect(() => {
    carregarDados();
  }, []);

  async function carregarDados() {
    setCarregando(true);
    const content = { proposta_uuid: props.proposta, multSelect: props?.detalhes?.multSelect }
    sendData({ uri: uriList('consultarStatusBanco'), content, method: "POST" })
      .then((res) => {
        console.log("PropostaStatusDetalhes.useEffect(res): ", res);
        setData(res?.message);
        setCarregando(false);
      })
      .catch((error) => {
        setCarregando(false);
      });

  }
  function Content() {
    console.log("PropostaStatusDetalhes.index(Content): ", data?.details);
    return (
      <>
        {data && !data?.consultaLote &&
          <ListGroup.Item
            style={{ backgroundColor: "#E8E8E8", color: "#636363", maxWidth: "100%", padding: "5px", }}
          >
            <div className="row">
              <div className="row ml-1 col-6">
                <strong>Status:&nbsp;</strong> {data?.descricao || data?.resultadoProcessamento?.descricao}
              </div>
              {bancoFornecedor() == "inbursa" &&
                <div className="row ml-1 col-6">
                  <strong>Mensagem:&nbsp;</strong> {data?.mensagem}
                </div>
              }
            </div>
            <div className="row">
              <div className="row ml-1 col-4">
                <strong>{bancoFornecedor() == "cef" ? "Número Contrato" : "ProposedId"}:&nbsp;</strong> {data?.proposedId || data?.numeroContrato}
              </div>
              {bancoFornecedor() == "inbursa" &&
                <div className="row ml-1 col-8">
                  <strong>ProposedGuid:&nbsp;</strong> {data.proposedGuid || data.numeroContrato}
                </div>
              }
            </div>
            <div className="row ml-0 col-12" style={{ borderTop: "1px solid #D3D3D3" }}>
            </div>
            <div className="row">
              <div className="row ml-1 col-12">
                <strong>Cliente:&nbsp;</strong> {props.detalhes.cliente}
              </div>

            </div>
            <div className="row">
              <div className="row ml-1 col-4">
                <strong>Cpf:&nbsp;</strong> {props.detalhes.cpf}
              </div>
              <div className="row ml-1 col-8">
                <strong>Ccb:&nbsp;</strong> {props.detalhes.cedula_numero}
              </div>
            </div>
          </ListGroup.Item>
        }
        <div style={{ maxHeight: width() == "mobile" ? "400px" : "600px", overflowY: "auto", overflowX: "hidden" }}>
          <ListGroup>
            {(data?.details || data?.situacoes)?.map((option, i) => {
              if (option?.description?.indexOf("Proposta NÃO averbada pela DataPrev") >= 0) {
                return null
              }
              // return (JSON.stringify(option))
              return (
                <>
                  <ListGroup.Item
                    key={i}
                    style={{ color: "#636363", padding: "0px", width: "100%" }}
                  >
                    <div className="row">
                      <div className={"col-12"}                    >
                        <div className="row ml-1 col-12">
                          <div className="row col-3">
                            {moment(option?.createdIn || option?.dataHora).format("DD/MM/YYYY HH:mm")}
                          </div>
                          <div className="row col-9">
                            {option?.description || (option?.descricao + " ( código:" + option?.codigo + " )" + " - " + option?.usuario?.trim())}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                </>
              );
            })}
          </ListGroup>
        </div>
      </>
    );
  }

  return (
    <>
      (
      <ModalContent
        title={`Status: ${data?.descricao || ""}`}
        onCloseEv={handleClose}
        size="lg"
        concorda={false}
      >
        <div style={{ display: (carregando || data?.consultaLote) ? 'flex' : '', justifyContent: (carregando || data?.consultaLote) ? 'center' : '' }}>
          {!carregando && <Content />}
          {carregando && <p>Buscando dados, aguarde...</p>}
          {data?.consultaLote && <p style={{ fontSize: '26px', fontWeight: 'bold' }}>{data?.consultaLote}</p>}
        </div>
      </ModalContent>
      )
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(PropostaStatusDetalhes);
