import React, { useState, useEffect } from "react";

import Form from "../../components/form/admin/portabilidadesV2";
import EnviarEmail from "../../components/form/admin/enviarEmail";
import ModalContent from "../../components/layout/ModalContent";
import GerirDocumentos from "../../components/form/admin/gerirDocumentos";
import Confirm from "../../views/simulator/confirm";
import IncluirPendencia from "../../components/form/admin/Pendencias/IncluirPendencia";

import { getData, sendData } from "../../helpers/auth";
import { uriList } from "../../helpers/config";
import { cidades } from "../../helpers/cidades";
import { getCookie, saveCookie } from "../../helpers/general";
import {
    sortByString,
    sortByNumber,
    compareDate,
    width,
    Aguarde,
} from "../../helpers/general";
import Averbacao from "../../components/form/admin/Averbacao";
import AlteracaoProposta from "../../components/form/admin/alteracaoProposta";
import Auxiliar from "../../components/form/admin/Auxiliar";
import LogProposta from "../../components/form/admin/LogProposta";
import AntiFraude from "../../components/form/admin/AntiFraude";
import AuditAssinatura from "../../components/form/admin/AuditAssinatura";
import Observacoes from "../../components/form/admin/observacoes";
import Status from "../../views/status";
import SaldoDevedor from "../../views/admin/saldoDevedor";
import ConsultarMargem from "./ConsultarMargem";
import AlterarSimulacao from "../../components/form/admin/CreditoPessoal/alterarSimulacao";
import EditarProposta from "./../../routes/propostaAgente";
import Portabilidade from "./../../routes/portabilidade";
import Refinanciamento from "./../../routes/refinPort";
import SimularPort from "../../components/form/simulator/simularPort";
import { Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import {
    setAlterarProposta,
    setBuscarPropostas,
    setProposta,
    setNotificacoes,
    setFiltrosPropostas
} from "../../store/actions/dados";
import { confirmSwal, swalError } from "../../components/swal";
import PreencherChecklist from "../../components/form/admin/Conformidade/PreencherChecklist";
import ShowConformidade from "../../components/form/admin/Conformidade/ShowConformidade";

function Portabilidades({ ...props }) {
    let estados = cidades();
    const [detailedPropostas, setDetailedPropostas] = useState([]);
    const [detailedShow, setDetailedShow] = useState([]);
    const [total, setTotal] = useState(false);
    const [clickedTr, setClickedTr] = useState(false);
    const [actualPage, setActualPage] = useState(0);
    const [filterOptions, setFilterOptions] = useState(false);
    const [filtro, setFiltro] = useState(false);
    const [ascDesc, setAscDesc] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [dateVisible, setDateVisible] = useState("todos");
    const [modal, setModal] = useState(false);
    const [averbacao, setAverbacao] = useState(false);
    const [pagamento, setPagamento] = useState(false);
    const [cancelamento, setCancelamento] = useState(false);
    const [inutilizacao, setInutilizacao] = useState(false);
    const [pendencia, setPendencia] = useState(false);
    const [bloqueio, setBloqueio] = useState(false);
    const [log, setLog] = useState(false);
    const [obs, setObs] = useState(false);
    const [status, setStatus] = useState(false);
    const [enviarEmail, setEnviarEmail] = useState(false);
    const [gerirDocs, setGerirDocs] = useState(false);
    const [action, setAction] = useState("");
    const [aguardando, _setAguardando] = useState(false);
    const [antiFraude, setAntiFraude] = useState(false);
    const [auditAssinatura, setAuditAssinatura] = useState(false);
    const [totalIni, setTotalIni] = useState(0);
    const [proposta_filter, setProposta_Filter] = useState("");
    const [simulation, setSimulation] = useState(false);
    const [simulationPort, setSimulationPort] = useState(false);
    const [multSelect, setMultSelect] = useState([]);
    const [buscando, setBuscando] = useState(false);
    const [consultarMargem, setConsultarMargem] = useState(false);
    const [solicitacaoSaldo, setSolicitacaoSaldo] = useState(false);
    const [alteracaoProposta, setAlteracaoProposta] = useState(false);
    const [alterarSimulacao, setAlterarSimulacao] = useState(false);
    const [exibirTodos, setNoLimit] = useState(getCookie({ label: "exibirTodos" }) || 'Nao')
    const [hideCanceled, setHideCanceled] = useState(getCookie({ label: "hideCanceled" }) || 'Nao')
    const [campoPesquisa, setCampoPesquisa] = useState('cpf')
    const [agentesGbl, setAgentesGbl] = useState(false)
    const [agentes, setAgentes] = useState(false)
    const [agentesMaster, setAgentesMaster] = useState(false)
    const [agenteId, setAgenteId] = useState(null)
    const [conformidade, setConformidade] = useState(false)
    const [qtdLinesPage, setQtdLinesPage] = useState(10)
    const [totais, setTotais] = useState(false)
    const [convenios, setConvenios] = useState(false)

    function handleMarcarTodos(todos = false) {
        let _multSelect = [clickedTr]
        if (multSelect.length === 0) {
            if (!todos)
                detailedShow.map(
                    (proposta, i) => {
                        //-- Diogo 02.08.2023 - Comentado pois a opção de marcar todos não estava funcionando
                        // if (i >= actualPage * qtdLinesPage && i < (actualPage + 1) * qtdLinesPage)
                        _multSelect.push(proposta.uuid)
                    })
            else
                for (let i = 0; i < detailedShow.length; i++) {
                    // console.log("detailedShow[i].uuid: ", detailedShow[i])
                    _multSelect.push(detailedShow[i].uuid)
                }
            setMultSelect(_multSelect)
        }
        else
            setMultSelect([])
    }

    function setAguardando(prm) {
        _setAguardando(prm);

        if (!prm) setBuscando(false);
    }

    function dblClick() {
        if (props.setAlterarProposta)
            props.setAlterarProposta(true, props.detalhes);

        // console.log("Detalhes: ", props.detalhes)
    }

    // useEffect(() => {
    //     setQtdLinesPage(getCookie({ label: "qtdLinesPage" }) || 10)
    // }, [getCookie({ label: "qtdLinesPage" })]);

    useEffect(() => {
        function callback(isConfirm) {
            setBuscando(false);
            let _action = action;
            setAction("");
            if (!isConfirm) return;
            // console.log("Executando ação: ", _action)
            //-- Modificando action manualmente
            if (
                _action.toLowerCase() == "enviarparaassinatura" &&
                props.detalhes.posicao.toLowerCase() == "assinando ccb"
            )
                _action = "reenviarParaAssinatura";

            setAguardando(true);
            const uuid = props.proposta;
            let content = { uuid, detalhes: props.detalhes };

            //-- Mudando content de uuid para proposta_uuid para não conflitar com uuid do documento, no caso da
            if (_action.toLowerCase() === "informarassinatura")
                content = { proposta_uuid: uuid };


            if (_action.toLowerCase() === "checarantifraude")
                content = { ...content, proposta_uuid: uuid, cpf: props?.detalhes?.cpf, forca: false }

            //-- Enviando para assinatura com token whatsapp
            if (_action.toLowerCase() === "enviarparaassinaturawhats")
                content = { ...content, whats: true };

            content["multSelect"] = multSelect;
            // console.log("Executando ação(1)")
            sendData({ uri: uriList(_action), content, method: "POST" })
                .then((res) => {
                    // console.log(res);
                    if (res && res.status && res.status != 200) {
                        if (
                            ["antifraude", "emailcliente", "reenviarparaassinatura"].indexOf(
                                _action.toLowerCase()
                            ) < 0
                        )
                            swalError({
                                title: "Erro ao executar ação!",
                                text: JSON.stringify(res.message),
                            });
                    }

                    if (_action === "antifraude") {
                        if (!res.status || res.status !== 200) {
                            // console.log()
                            setAntiFraude(res);
                        } else setAguardando(false);
                    } else if (_action === "gerarrefinport")
                        refresh(true);
                    else
                        refresh();
                })
                .catch((error) => {
                    setAguardando(false);
                    console.log("Erro - Action: ", _action);
                    // console.log("Erro: ", error)
                    if (_action !== "antifraude")
                        swalError({ title: "Erro ao executar ação!", text: error });
                });
            setAction("");
        }

        if (action) {
            confirmSwal({ callback, title: "Confirma a execução do processo?" });
        }
    }, [action, refresh]);

    function getAgenteId() {
        if (!agentesGbl)
            return null

        const _agente = agentesGbl.find((agente) => agente.nome === filtro.agente);
        return _agente?.id;
    }

    function getAgenteMstId() {
        if (!agentesGbl)
            return null

        const _agente = agentesGbl.find((agente) => agente.usermst_nome === filtro.agentemaster);
        return _agente?.usermst_id;
    }

    async function _getAgentesMst() {
        await sendData({
            uri: uriList("listarAgentesMst"),
            content: {},
            method: "POST",
        })
            .then((res) => {
                setAgentesGbl(res)
                console.log("_getAgentesMst.Agentes: ", res)
                setAgentes(res.map((agente) => agente.nome));

                // console.log("propostas._getAgentesMst: ", _agentes, _master)
                let _agenteid = props.detalhes.agente_id || (res && res[0] && res[0].id);
                setAgenteId(_agenteid);
                console.log("alteracaoProposta._getAgentesMst(agente): ", _agenteid)
            })
            .catch((e) => {
                console.log(e);
                alert("Erro ao obter Agentes.\n" + e.message);
            });
    }

    async function _getAgentes(convenio_id) {
        console.log("portabilidades._getAgentes: ", props?.user)
        if (['ADMINISTRADOR', 'OPERADOR'].indexOf(props?.user?.tipo) < 0) {
            _getAgentesMst()
            return false
        }

        console.log("propostas._getAgentes: ", convenio_id)

        await sendData({
            uri: uriList("listarAgentes"),
            content: {},
            method: "POST",
        })
            .then((res) => {
                let _master = []
                let _agentes = []
                setAgentesGbl(res)
                console.log("_getAgentes.Agentes: ", res)
                const _res = res?.map((item) => {
                    if (item.usermst_id && _master.indexOf(item.usermst_nome) < 0)
                        _master.push(item.usermst_nome)

                    if (item.nome && _agentes.indexOf(item.nome) < 0)
                        _agentes.push(item.nome)
                });

                _master.sort()
                _agentes.sort()

                setAgentes(_agentes);
                setAgentesMaster(_master);

                // console.log("propostas._getAgentes: ", _agentes, _master)
                let _agenteid = props.detalhes.agente_id || (res && res[0] && res[0].id);
                setAgenteId(_agenteid);
                console.log("alteracaoProposta._getAgentes(agente): ", _agenteid)
            })
            .catch((e) => {
                console.log(e);
                alert("Erro ao obter Agentes.\n" + e.message);
            });
    }

    const acompanharProposta = () => {
        return new Promise((resolve, reject) => {
            let uuid = props.proposta || props.uuid || props.uuid
            console.log("portabilidades - alterarProposta(1): ", uuid)
            sendData({ uri: uriList('acompanharProposta'), content: { uuid }, method: 'POST' })
                .then(res => {
                    console.log("portabilidades - alterarProposta(2): ", res?.id)
                    props.setProposta(res);
                    resolve(true)
                })
                .catch(e => {
                    reject(false)
                });
        })
    }

    useEffect(() => {
        executarAcao()
    }, [props.action,]);

    async function executarAcao() {
        function callBack() {
            setBuscando(false);
            if (props.action === "pendenciar") setPendencia(true);
            else if (props.action === "bloquear") setBloqueio(true);
            else if (props.action === "averbar") setAverbacao(true);
            else if (props.action === "informarPagamento") setPagamento(true);
            else if (props.action === "cancelar") setCancelamento(true);
            else if (props.action === "inutilizar") setInutilizacao(true);
            else if (props.action === "log") setLog(true);
            else if (props.action === "auditAssinatura") setAuditAssinatura(true);
            else if (props.action === "obs") setObs(true);
            else if (props.action === "consultarMargem") setConsultarMargem(true);
            else if (props.action === "alterarSimulacao")
                acompanharProposta().then(setAlterarSimulacao);
            else if (props.action === "status")
                acompanharProposta().then(setStatus);
            else if (props.action === "enviarEmail") setEnviarEmail(true);
            else if (props.action === "gerirDocs") setGerirDocs(true);
            else if (props.action === "simulation")
                acompanharProposta().then(setSimulation);
            else if (props.action === "simularPortabilidade") setSimulationPort(true);
            else if (props.action === "conformidade") setConformidade(true);
            else if (props.action === "consultarSolicitacaoSaldo")
                setSolicitacaoSaldo(true);
            else if (props.action === "assinarRefinPort" && props.setAlterarProposta)
                props.setAlterarProposta(true, props.detalhes);
            else if (props.action === "alteracaoProposta")
                acompanharProposta().then(setAlteracaoProposta);
            else setAction(props.action);
        }

        callBack()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function refresh(close, forca) {
        const funcAtu = () => {
            setActualPage(0);
            props.setNotificacoes("limpar");
        };
        // console.log("portabilidades - refresh");
        setBuscando(false);
        setAguardando(false);
        setAverbacao(false);
        setPagamento(false);
        setCancelamento(false);
        setInutilizacao(false);
        setPendencia(false);
        setBloqueio(false);
        setEnviarEmail(false);
        setAntiFraude(false);
        setGerirDocs(false);
        setLog(false);
        setAuditAssinatura(false);
        setObs(false);
        setStatus(false);
        setConsultarMargem(false);
        setAlterarSimulacao(false);
        setAlteracaoProposta(false);
        setConformidade(false);

        //-- Atualizando dados
        console.log("portabilidades - refresh(1): ", { close, forca, mult: props?.detalhes?.multSelect?.length })
        if (!close && (!props?.detalhes?.multSelect || props?.detalhes?.multSelect?.length <= 1)) {
            if (!forca) getProposta(funcAtu);
            else {
                getPropostas(true, 'refresh');
                funcAtu();
            }
        }
    }

    const sortArrObj = (
        key,
        type,
        array = null,
        grava = true,
        ordem = ascDesc
    ) => {
        if (!detailedShow) return;
        let arr = [...detailedShow];
        let asc = !ascDesc;
        if (array) {
            asc = ordem;
            arr = array;
        }
        arr = arr.sort((a, b) => {
            if (type === "string") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByString(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByString(b, a, key, type);
                }
            } else if (type === "numeric") {
                if (asc) {
                    setAscDesc(asc);
                    return sortByNumber(a, b, key, type);
                } else {
                    setAscDesc(asc);
                    return sortByNumber(b, a, key, type);
                }
            } else {
                if (asc) {
                    setAscDesc(asc);
                    return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
                } else {
                    setAscDesc(asc);
                    return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
                }
            }
        });

        if (grava)
            localStorage.setItem(
                "portabilidades_ordem",
                JSON.stringify({ key, type, asc: !ascDesc })
            );

        if (!array) setDetailedShow(arr);

        return array;
    };

    const filtrarPropostas = (filtros, portabilidades) => {
        // console.log("portabilidades - filtrarPropostas: ", { filtros, portabilidades, hideCanceled });
        let newDetailed = portabilidades;
        let _total = 0;
        newDetailed = portabilidades.filter((proposta) => {
            let result = true;
            if (hideCanceled == 'Sim' && proposta.posicao == "Cancelada") {
                // console.log("portabilidades - filtrarPropostas(cancelada): ", { hideCanceled });
                result = false
            }
            else
                for (let _filtro in filtros) {
                    if (filtros[_filtro] && filtros[_filtro] != "todos") {
                        if (_filtro == "averbacao_solicitada") {
                            result =
                                (filtros[_filtro] == "Sim" && proposta["averbacao_solicitada"]) ||
                                (filtros[_filtro] == "Nao" && !proposta["averbacao_solicitada"]);
                        } else if (
                            _filtro === "status_data" ||
                            _filtro === "created_at" ||
                            _filtro === "previsao_saldo"
                        ) {
                            result =
                                result &&
                                compareDate(new Date(proposta[_filtro]), startDate) &&
                                compareDate(finalDate, new Date(proposta[_filtro]));
                        } else if (_filtro === "proposta_filter") {
                            // console.log("portabilidades - filtrarPropostas: (filtro)", filtros[_filtro])
                            // console.log("portabilidades - filtrarPropostas: (nome)", proposta["nome"])
                            result =
                                result &&
                                ((proposta["nome"] &&
                                    proposta["nome"]
                                        .toLowerCase()
                                        .indexOf(filtros[_filtro].toLowerCase()) >= 0) ||
                                    (proposta["uuid"] &&
                                        proposta["uuid"].indexOf(filtros[_filtro]) >= 0) ||
                                    (proposta["proposta_uuid"] &&
                                        proposta["proposta_uuid"].indexOf(filtros[_filtro]) >= 0) ||
                                    (proposta["agente"] &&
                                        proposta["agente"]
                                            .toUpperCase()
                                            .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                                    (proposta["agentemaster"] &&
                                        proposta["agentemaster"]
                                            .toUpperCase()
                                            .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                                    (proposta["operador"] &&
                                        proposta["operador"]
                                            .toUpperCase()
                                            .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                                    (proposta["conformidadeapv"] &&
                                        proposta["conformidadeapv"]
                                            .toUpperCase()
                                            .indexOf(filtros[_filtro].toUpperCase()) >= 0) ||
                                    (proposta["cpf"] &&
                                        filtros[_filtro].replace(/\D/g, "") != "" &&
                                        proposta["cpf"]
                                            .replace(/\D/g, "")
                                            .indexOf(filtros[_filtro].replace(/\D/g, "")) >= 0) ||
                                    (proposta["cedula_numero"] &&
                                        filtros[_filtro].replace(/\D/g, "") != "" &&
                                        proposta["cedula_numero"]
                                            .replace(/\D/g, "")
                                            .indexOf(filtros[_filtro].replace(/\D/g, "")) >= 0));
                            // console.log("portabilidades - filtrarPropostas: (result)", result)
                        } else result = result && proposta[_filtro] === filtros[_filtro];
                    }
                }

            if (result)
                _total += parseFloat(
                    proposta.operacao.toLowerCase() == "refinanciamento"
                        ? proposta.valor_liquido
                        : proposta.valor_total
                );

            return result;
        });

        // console.log("portabilidades - filtrarPropostas(1): ", { newDetailed, _total });
        setTotal(_total);
        createFilter(newDetailed);

        return newDetailed;
    };

    const handleOption = (e) => {
        let label = e && e.currentTarget.dataset.label;
        let value = (e && e.currentTarget.value) || [startDate, finalDate];
        localStorage.setItem("portabilidades_ordem", null);

        let _filtros;
        if (
            label === "created_at" ||
            label === "status_data" ||
            label === "previsao_saldo" ||
            !label
        )
            _filtros = {
                ...filtro,
                status_data: null,
                created_at: null,
                previsao_saldo: null,
                [label]: value,
            };
        else if (label) _filtros = { ...filtro, [label]: value }
        else
            _filtros = { ...filtro, [label]: value }

        setFiltro(_filtros);
        props.setFiltrosPropostas(_filtros);
        //-- Diogo 02.08.2023 - Retirar seleção ao mudar valor    

        // let result = filtrarPropostas(_filtros, detailedPropostas);
        // setDetailedShow(result);
        // setActualPage(0);
    };

    const handlePesquisar = () => {
        console.log("portabilidades - handlePesquisar: ", proposta_filter);
        getPropostas(false, 'handlePesquisar', 0)
    }

    const handleClearOptions = () => {
        setProposta_Filter();
        setFilterOptions(false);
        // console.log("FilterOptions: ", filterOptions);

        setDateVisible("todos");
        setProposta_Filter("");
        setTotal(totalIni);
        setFiltro(false);
        setClickedTr(false);
        localStorage.setItem("portabilidades_clickedTr", JSON.stringify({}));
        localStorage.setItem("portabilidades_ordem", JSON.stringify({}));
        setMultSelect([]);
        setCampoPesquisa('cpf')
        setDetailedPropostas([]);
        setDetailedShow([]);
        setTotais({});

        //-- Diogo 01.08.2023 - Ao limpar não buscar registros
        refresh(true);

        //-- Diogo 25.07.2023 - Executando o método de busca duas vezes
        // getPropostas(true, 'handleClearOptions');
    };

    const handleQtdLinesPage = (value) => {
        setQtdLinesPage(value);
        setActualPage(0);
        getPropostas(true, 'handleQtdLinesPage', 0, value);
    }

    const handlePage = (e) => {
        const _page = e?.currentTarget?.value
        console.log("portabilidades.handlePage: ", _page)
        setActualPage(_page);
        if (actualPage != _page)
            setMultSelect([]);

        getPropostas(true, 'handlePage', _page)
    };

    const createFilter = (obj) => {
        // console.log("createFilter")
        let newObj = {
            created_at: [],
            status_data: [],
            previsao_saldo: [],
            cpf: [],
            tabela: [],
            convenio: [],
            agentemaster: [],
            agente: [],
            operador: [],
            conformidadeapv: [],
            posicao: [],
            operacao: [],
            averbacao_solicitada: ["Sim", "Nao"],
        };

        for (let o of obj) {
            for (let i in o) {
                if (i !== "averbacao_solicitada") {
                    if (
                        newObj[i] &&
                        i !== "created_at" &&
                        i !== "status_data" &&
                        i !== "previsao_saldo"
                    ) {
                        newObj[i].push(o[i]);
                    } else if (
                        i === "created_at" ||
                        i === "status_data" ||
                        i === "previsao_saldo"
                    ) {
                        newObj[i].push(new Date(o[i]).toLocaleDateString());
                    }
                }
            }
        }

        for (let i in newObj) {
            newObj[i] = [...new Set(newObj[i])];
        }

        // console.log("Propostas - Filtros: ", newObj);
        setFilterOptions(newObj);
    };

    function handleNoLimit(e) {
        let value = e.currentTarget.value
        saveCookie({ label: "exibirTodos", content: value })
        setNoLimit(value)
    }

    function handleHideCanceled(e) {
        let value = e.currentTarget.value
        saveCookie({ label: "hideCanceled", content: value })
        setHideCanceled(value)
    }

    // useEffect(() => {
    //     const result = filtrarPropostas(filtro, detailedPropostas);
    //     setDetailedShow(result);
    //     setActualPage(0);
    // }, [hideCanceled])

    useEffect(() => {
        async function callBack() {
            getData({ uri: uriList("conveniosFilter") })
                .then((res) => {
                    if (res.status && res.status > 200) throw new Error();
                    console.log("Convenios: ", res);
                    let _convenios = []
                    res.map((item) => _convenios.push(item.value))
                    console.log("Convenios(1): ", _convenios);
                    setConvenios(_convenios);
                })
                .catch((e) => {
                    return false;
                });
        }

        callBack();
    }, []);

    // useEffect(() => {
    //     getPropostas(false, 'useEffect-operacao')
    // }, [props.operacao])

    const handleCampoPesquisa = (e) => {
        let value = e.currentTarget.value;
        setCampoPesquisa(value);
        console.log("portabilidades - handleCampoPesquisa: ", value);
    }

    async function getPropostas(forca, teste, page, linesPage, refazendo = false) {
        console.log("portabilidade.getPropostas(teste): ", teste)
        setMultSelect([]);

        setDetailedPropostas([]);
        setDetailedShow([]);
        setTotal(0);
        setTotais({});
        setTotalIni(0);
        if (!page || page == undefined)
            setActualPage(0);

        let setarClicado = false;
        let clicado = localStorage.getItem("portabilidades_clickedTr");
        if (clicado) clicado = JSON.parse(clicado);

        let ordem = localStorage.getItem("portabilidades_ordem");
        if (ordem) ordem = JSON.parse(ordem);

        // console.log("portabilidades.getPropostas(campoPesquisa)", campoPesquisa?.currentTarget?.value)
        setBuscando(true);
        await sendData({
            uri: uriList("resultado"),
            content: {
                noLimit: true,
                campoPesquisa: campoPesquisa,
                proposta_filter: proposta_filter && proposta_filter?.trim().toUpperCase(),
                dateVisible,
                startDate,
                finalDate,
                hideCanceled,
                // operacao: props?.operacao,
                filtro: { ...filtro, operacao: props?.operacao },
                actualPage: (page || 0) + 1,
                qtdLinesPage: linesPage || qtdLinesPage,
                agente_id: getAgenteId(),
                agentemst_id: getAgenteMstId(),
            },
            method: "POST",
        })
            .then((res) => {
                setBuscando(false);
                const { data, totais, usuarioMstNomes } = res

                if (!res || res?.status > 200 || data == undefined || totais == undefined) throw new Error();

                console.log("portabilidades.getPropostas(ok): ", res)

                let _res;
                if (ordem && ordem.key)
                    _res = sortArrObj(ordem.key, ordem.type, data, false, ordem.asc);
                else _res = data;
                setTotais(totais);

                //-- Filtrando portabilidades
                // console.log("portabilidades - getPropostas(y):", filtro);
                setDetailedPropostas(_res);
                setDetailedShow(_res);

                // let result = filtrarPropostas(forca ? {} : filtro, _res);
                // setDetailedShow(result);

                // console.log("portabilidades.getPropostas(ok-1)")
                // let _total = 0;
                // result.map((proposta) => {
                //     _total += parseFloat(
                //         proposta.operacao.toLowerCase() == "refinanciamento"
                //             ? proposta.valor_liquido
                //             : proposta.valor_total
                //     );

                //     if (clicado && proposta.uuid === clicado.clickedTr)
                //         setarClicado = true;

                //     return proposta.uuid;
                // });

                // console.log("portabilidades.getPropostas(ok-2)")
                // setTotal(_total);
                // setTotalIni(_total);

                // if (setarClicado) {
                //     // console.log("Setando proposta clicada: ", clicado);
                //     setActualPage(clicado.actualPage);
                //     handleClickTr(clicado.clickedTr);
                //     setMultSelect([...multSelect, clicado.clickedTr]);
                // }
                // else if (page == undefined) {
                //     setActualPage(clicado?.actualPage || 0);
                // }
            })
            .catch((e) => {
                setBuscando(false);
                console.log("erro ao buscar propostas: ", e);
                if (!refazendo)
                    getPropostas(forca, teste, page, linesPage, true)
                else
                    swalError({ title: "Erro ao buscar propostas, tente novamente.", text: e?.message || e?.data || "Erro desconhecido!" });
            });
    }

    function getProposta(func) {
        let clicado = localStorage.getItem("portabilidades_clickedTr");
        if (clicado) clicado = JSON.parse(clicado);
        let content = { uuid: clicado?.clickedTr || clickedTr?.uuid };
        let prop = detailedPropostas.filter((proposta) => {
            return proposta.uuid === content.uuid;
        })[0];

        // console.log("getProposta: ", content);
        // console.log("getProposta(1): ", detailedPropostas);
        sendData({ uri: uriList("acompanhar"), content, method: "POST" })
            .then((res) => {
                if (res.status && res.status > 200) throw new Error();

                if (res && res.length > 0) {
                    // console.log("getProposta(2): ", prop);
                    // console.log("getProposta(2.1): ", res[0]);
                    if (!prop || prop.posicao != res[0].posicao) {
                        getPropostas(false, 'getProposta');
                        if (func) func();
                    }
                }
            })
            .catch((e) => {
                console.log("erro ao buscar proposta: ", e);
                return false;
            });
    }

    useEffect(() => {
        if (!props.proposta) props.setAlterarProposta(false, props.detalhes);
        console.log("Diogo teste - busca de propostas")
        //-- Diogo voltar? 18.04.2023
        // if (props.setBuscarPropostas)
        //     props.setBuscarPropostas(() => refresh(false, true));

        //-- Diogo voltar? 18.04.2023
        // refresh(false, true);

        _getAgentes();
    }, []);

    useEffect(() => {
        createFilter(detailedPropostas);
    }, [detailedPropostas]);

    function handleProposta(e) {
        // console.log("portabilidades - handleProposta: ", e.currentTarget);

        setProposta_Filter(e.currentTarget.value);
        localStorage.setItem("portabilidades_ordem", null);

        let _filtros = { ...filtro, proposta_filter: e.currentTarget.value };
        setFiltro(_filtros);

        //-- Diogo 02.08.2023 - Retirado filtro ao digitar
        // let result = filtrarPropostas(_filtros, detailedPropostas);
        // setDetailedShow(result);

        // setActualPage(0);
    }

    function handleClickTr(value, event) {
        setClickedTr(value);

        localStorage.setItem(
            "portabilidades_clickedTr",
            JSON.stringify({ clickedTr: value, actualPage })
        );

        if (event && event.ctrlKey) {
            if (multSelect.indexOf(value) < 0) setMultSelect([...multSelect, value]);
        } else if (!event?.context || multSelect.length <= 1) setMultSelect([value]);

    }

    function StatusModal() {
        return (
            <ModalContent
                title="Status da Proposta"
                onCloseEv={() => {
                    refresh(true);
                }}
                size={"lg"}
                concorda={false}
            >
                <Status
                    preencherCli={false}
                    // title={width() === 'mobile' ? 'ACOMPANHAMENTO' : "ACOMPANHAMENTO DE PROPOSTA"}
                    hideButton={true}
                    hideTitle={true}
                    modal={true}
                    data={props.detalhes}
                />
            </ModalContent>
        );
    }

    return (
        <>
            {!props.alterarProposta && (
                <Form
                    filterOptions={filterOptions}
                    proposta_filter={proposta_filter}
                    handleProposta={handleProposta}
                    handleOption={handleOption}
                    handleClearOptions={handleClearOptions}
                    setClickedTr={handleClickTr}
                    setDateVisible={setDateVisible}
                    setFinalDate={setFinalDate}
                    setStartDate={setStartDate}
                    setModal={setModal}
                    sortArrObj={sortArrObj}
                    detailedShow={detailedShow}
                    actualPage={actualPage}
                    handlePage={handlePage}
                    finalDate={finalDate}
                    startDate={startDate}
                    dateVisible={dateVisible}
                    clickedTr={clickedTr}
                    multSelect={multSelect}
                    detailedPropostas={detailedPropostas}
                    DblClick={dblClick}
                    total={total}
                    context={props?.context}
                    exibirTodos={exibirTodos}
                    setNoLimit={handleNoLimit}
                    buscando={buscando}
                    hideCanceled={hideCanceled}
                    setHideCanceled={handleHideCanceled}
                    // buscarPropostas={() => getPropostas(true)}
                    handlePesquisar={handlePesquisar}
                    setCampoPesquisa={handleCampoPesquisa}
                    campoPesquisa={campoPesquisa}
                    handleMarcarTodos={handleMarcarTodos}
                    qtdLinesPage={qtdLinesPage}
                    setQtdLinesPage={handleQtdLinesPage}
                    totais={totais}
                    convenios={convenios}
                    agentes={agentes}
                    agentesMaster={agentesMaster}
                    filtro={filtro}
                />
            )}

            {alteracaoProposta && (
                <AlteracaoProposta
                    onCloseEv={() => {
                        refresh(true);
                    }}
                    onHandle={() => refresh(false, true)}
                    estados={estados}
                    agentes={agentesGbl}
                    agenteId={agenteId}
                />
            )}

            {props.alterarProposta &&
                (props.detalhes.operacao == "PORTABILIDADE" ? (
                    props.detalhes.assinada || props.detalhes.assinando_refinport ? (
                        <Refinanciamento alterar={true} />
                    ) : (
                        <Portabilidade alterar={true} />
                    )
                ) : (
                    <EditarProposta />
                ))}
            {antiFraude && (
                <AntiFraude
                    onCloseEv={() => {
                        refresh(true);
                    }}
                    res={antiFraude}
                />
            )}
            {auditAssinatura && (
                <AuditAssinatura
                    onCloseEv={() => {
                        refresh(true);
                    }}
                />
            )}
            {averbacao && <Averbacao onCloseEv={refresh} tela="averbacao" />}
            {pagamento && <Averbacao onCloseEv={refresh} tela="pagamento" />}
            {pendencia && (
                <IncluirPendencia
                    onCloseEv={refresh}
                    uri="pendenciar"
                    title="Pendenciar Proposta"
                />
            )}
            {bloqueio && (
                <Auxiliar
                    onCloseEv={refresh}
                    uri="bloquear"
                    title="Bloquear Proposta"
                />
            )}
            {cancelamento && (
                <Auxiliar
                    onCloseEv={refresh}
                    multSelect={true}
                    uri="cancelar"
                    title="Cancelar Proposta"
                    cancelar
                />
            )}
            {inutilizacao && (
                <Auxiliar
                    onCloseEv={refresh}
                    uri="inutilizar"
                    title="Inutilizar Proposta"
                />
            )}
            {log && (
                <LogProposta
                    onCloseEv={() => {
                        refresh(true);
                    }}
                />
            )}
            {obs && (
                <Observacoes
                    onCloseEv={() => {
                        refresh(true);
                    }}
                />
            )}
            {status && <StatusModal />}
            {simulation && (
                <Confirm
                    onCloseEv={() => {
                        refresh(true);
                        setSimulation(false);
                    }}
                    onAccept={() => {
                        refresh(false);
                        setSimulation(false);
                    }}
                />
            )}
            {simulationPort && (
                <SimularPort
                    onCloseEv={() => {
                        refresh(true);
                        setSimulationPort(false);
                    }}
                    onAccept={() => {
                        refresh(false);
                        setSimulationPort(false);
                    }}
                />
            )}
            {solicitacaoSaldo && (
                <SaldoDevedor
                    onCloseEv={() => {
                        refresh(true);
                        setSolicitacaoSaldo(false);
                    }}
                    onAccept={() => {
                        refresh(false);
                        setSolicitacaoSaldo(false);
                    }}
                />
            )}
            {enviarEmail && (
                <EnviarEmail
                    onCloseEv={() => {
                        refresh(true);
                    }}
                    onSend={() => {
                        setAguardando(true);
                    }}
                    onEndSend={() => {
                        setAguardando(false);
                    }}
                />
            )}
            {gerirDocs && (
                <GerirDocumentos
                    onCloseEv={() => {
                        refresh(true);
                    }}
                />
            )}
            {consultarMargem && <ConsultarMargem onCloseEv={() => refresh(true)} />}

            {conformidade &&
                <ShowConformidade
                    handleCloseEv={() => {
                        setModal(false)
                        setConformidade(false)
                    }} />
            }
            {alterarSimulacao && (
                <AlterarSimulacao
                    alterarSimulacao={true}
                    onCloseEv={() => refresh(false, true)}
                />
            )}
            {(aguardando || buscando) && (
                <Aguarde
                    legenda={
                        buscando
                            ? "Aguarde, buscando portabilidades..."
                            : "Aguarde enquanto o processo é finalizado..."
                    }
                />
            )}

            {modal}
        </>
    );
}

function mapStateToProps(state) {
    return {
        proposta: state.dados.proposta,
        detalhes: state.dados.detalhes,
        user: state.dados.user,
        alterarProposta: state.dados.alterarProposta,
        buscarPropostas: state.dados.bucarPropostas,
    };
}

function mapActionToProps(dispatch) {
    return {
        setProposta(novaProposta) {
            dispatch(setProposta(novaProposta));
        },
        setAlterarProposta(alterar, proposta) {
            dispatch(setAlterarProposta(alterar, proposta));
        },
        setBuscarPropostas(buscar) {
            dispatch(setBuscarPropostas(buscar));
        },
        setNotificacoes(notificacao) {
            dispatch(setNotificacoes(notificacao));
        },
        setFiltrosPropostas(filtros) {
            dispatch(setFiltrosPropostas(filtros));
        },
    };
}

export default connect(mapStateToProps, mapActionToProps)(Portabilidades);
