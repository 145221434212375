import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { bancoFornecedor } from "./auth";
import html2canvas from "html2canvas";
import { temPermissao } from "./Uteis";

//-----------------------
export const openWithModal = (props) => {
  // render () {
  //   const url = new URL(this.props.href)
  //   if (url.hostname === WEBSITE_HOSTNAME) return (
  //     <a href={this.props.href}>{this.props.children}</a>
  //   )s

  return (
    <>
      <Modal show={true}>
        <iframe src={props.href} />
      </Modal>
    </>
  );
};

const supportDom = () => {
  if (!window.DOMParser) return false;
  var parser = new DOMParser();
  try {
    parser.parseFromString("x", "text/html");
  } catch (err) {
    return false;
  }
  return true;
};

export const getValoresProposta = () => {
  return ["Menor ou igual a 10.000,00", "Maior que 10.000,00"];
};

export const getOrigensProposta = () => {
  return ["Manual", "Importacao"];
};

export const getOperacoes = () => {
  return [
    "CONTRATO NOVO",
    "CREDITO PESSOAL",
    "FGTS",
    "PORTABILIDADE",
    "REFINANCIAMENTO",
    "CARTAO",
  ];
};

export const getStatus = () => {
  return [
    "Analise Esteira",
    "Aguardando Pagamento",
    "Aguardando Pagto Saldo",
    "Aguardando Refin",
    "Aguardando Saldo",
    "Aprovada Banco",
    "Aprovada CTC",
    "Assinando CCB",
    "Assinando CCB Port",
    "Assinando CCB RefinPort",
    "Autorizando Portabilidade",
    "Averbacao Solicitada",
    "Averbada",
    "Aprovada",
    "Bloqueada",
    "Cancelada",
    "Conformidade Aprovada",
    "Enviada para Banco",
    "Negada",
    "Pago",
    "Pendente",
    "Portabilidade Autorizada",
    "Portabilidade Averbada",
    "Proposta Assinada",
    "Proposta Port Assinada",
    "Saldo Formalizado",
    "Saldo Recebido",
    "Simulacao",
  ];
};

export const exportAsImage = async (el = "root", imageFileName, user) => {
  console.log("general.exportAsImage(0)");
  let [latitude, longitude] = getCookie({ label: "geolocation" })
    ? getCookie({ label: "geolocation" }).split(",")
    : ["", ""];
  console.log("general.exportAsImage(0.1)");
  let jsonGeo = { latitude, longitude, data: new Date(), user };

  console.log("general.exportAsImage(1): ", jsonGeo);
  var element = document.getElementById(el);
  if (element) {
    console.log("general.exportAsImage(2) ");
    element.setAttribute("type", "hidden");
    console.log("general.exportAsImage(2.1) ");
    element.innerHTML =
      "<div><i>" + JSON.stringify(jsonGeo) + "</i></div>" + element.innerHTML;
    console.log("general.exportAsImage(2.2) ");
    let canvas = await html2canvas(element);

    console.log("general.exportAsImage(3) ");
    const imageBase64 = canvas.toDataURL("image/png");

    console.log("general.exportAsImage(4) ");
    //-- Converting base64 image to PNG
    var arr = imageBase64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    console.log("general.exportAsImage(5) ");
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    console.log("general.exportAsImage(6): ", imageFileName);
    return new File([u8arr], imageFileName, { type: mime });
  } else return false;
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

//--------------------
export const Aguarde = (props) => {
  return (
    <Modal
      size={width() == "mobile" ? "sm" : props.size || "md"}
      scrollable
      show={true}
      animation={false}
      backdrop="static"
      centered
      // aria-labelledby="contained-modal-title-vcenter"
      {...props}
    >
      <Modal.Body
        // className="maxWidth750"
        style={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            margin: "10px",
            fontSize: width() == "mobile" ? "small" : "",
          }}
        >
          <Spinner
            animation="border"
            size={width() == "mobile" ? "sm" : "md"}
            style={{ marginRight: "5px", color: "#777575" }}
          />
          {props.legenda || "Aguarde enquanto o processo é finalizado..."}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const LOGRADOUROS =
  "Alameda, Avenida, Chácara, Condomínio, Conjunto, Distrito, Fazenda, Loteamento, Quadra, Residencial, Rua, Setor, Sítio, Via, Viela, Vila, Outros";
export const HORARIOLIST = [
  {
    id: 1,
    descricao: "Manhã (das 8 às 12h)",
    value: "08-12h",
  },
  {
    id: 2,
    descricao: "Tarde (das 13 às 17h)",
    value: "13-17h",
  },
  {
    id: 3,
    descricao: "Noite (das 18 às 20h)",
    value: "18-20h",
  },
];

export const handleFocus = (e) => {
  e.currentTarget.setSelectionRange(0, e.currentTarget.value.length);
};

export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const numerosIndividuais = (valor) => {
  let result = "";
  valor = valor.replace(/[\D]+/g, "");
  for (let chr of valor) {
    if (result.indexOf(chr) < 0) result += chr;
  }

  return result;
};

export const formatFloat = (numero, casas = 2) => {
  if (!numero) {
    numero = "0";
  }
  let valor = numero.toString();

  let result = valor;
  if (valor.indexOf(",") >= 0) {
    result = valor
      .replace(/^(0\.+)(\d)/g, "$2")
      .replace(".", "")
      .replace(",", ".");
  }

  return (result * 1).toFixed(casas);
};

export const primeirosNomes = (nome, qtd) => {
  let result = "";
  if (nome) {
    let _nome = nome.split(" ");
    for (let i = 0; i < qtd; i++)
      if (i < _nome.length) result += " " + _nome[i];

    result = result.trim();
  }
  return result;
};

export const formatCurrency = (val, casas = 2) => {
  // console.log(`FormatCurrency - Valor: ${val}, Casas: ${casas}`);
  let valor;
  if (val) {
    let expressao = /([0-9]{2})$/g;
    let expressaoM = /([0-9]{3}),([0-9]{2}$)/g;
    if (casas > 2)
      switch (casas) {
        case 3:
          expressao = /([0-9]{3})$/g;
          expressaoM = /([0-9]{3}),([0-9]{3}$)/g;
          break;
        case 4:
          expressao = /([0-9]{4})$/g;
          expressaoM = /([0-9]{3}),([0-9]{4}$)/g;
          break;
        case 5:
          expressao = /([0-9]{5})$/g;
          expressaoM = /([0-9]{3}),([0-9]{5}$)/g;
          break;
        default:
          break;
      }
    expressao = /([0-9]{2})$/g;
    expressaoM = /([0-9]{3}),([0-9]{2}$)/g;

    valor = val.toString();
    valor = valor + "";
    valor = valor.replace(/[\D]+/g, "");
    valor = valor + "";
    valor = valor.replace(expressao, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(expressaoM, ".$1,$2");
    }

    if (valor.indexOf("0.") >= 0) valor = valor.replace(/^(0\.+)(\d)/g, "$2");
  }

  return valor;
};

export const mCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return cpf;
};

export const mDate = (v) => {
  if (v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");
    v = v.replace(/(\d{2})(\d)/, "$1/$2");

    v = v.replace(/(\d{2})(\d{2})$/, "$1$2");
  }

  return v;
};

export const mDateBD = (v) => {
  if (v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
  }

  return v;
};

export const mCEP = (cep) => {
  cep = cep.replace(/\D/g, "");
  cep = cep.replace(/^(\d{5})(\d)/, "$1-$2");

  return cep;
};

export const mTel = (v) => {
  // console.log("mTel")
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return v;
  // v = v.replace(/\D/g, "");
  // // v = v.replace(/^(\d{2})(\d)/g, "($1)$2");
  // v = v.replace(/(\d)(\d{4})$/, "$1-$2");

  // return v;
};

export const mDDD = (v) => {
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})$/, "$1");
  if (v.length === 2) v = "0" + v;
  return v;
};

export const compareDate = (d1, d2) => {
  d1 = d1.toLocaleDateString();
  d2 = d2.toLocaleDateString();

  d1 = d1.split("/");
  d2 = d2.split("/");

  if (d1[2] === d2[2]) {
    if (d1[1] === d2[1]) {
      if (d1[0] >= d2[0]) return true;
      return false;
    } else if (d1[1] < d2[2]) {
      return false;
    }

    return true;
  } else if (d1[2] < d2[2]) {
    return false;
  }

  return true;
};

export const width = (allSizes = false, _width = false) => {
  let w = window.innerWidth;
  let result = "desktop";

  // console.log("Largura da tela(innerWidth):", w)
  if (w < 1000) result = "medium";
  if (w < 800) result = "mobile";
  if (w < 600 && allSizes) result = "mobile-small";
  if (w < 400 && allSizes) result = "mobile-xsmall";

  // console.log("general.width", { w, result });
  return _width ? w : result;
};

export const createNewCookie = ({ label, content }) => {
  document.cookie = `${label}=${content}; expires=Thu, 18 Dec 2050 12:00:00 UTC`;
};

export const getLocation = () => {
  let result = { latitude: 0, longitude: 0 };
  saveCookie({ label: "geolocation", content: "" });
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log("position(0)", position.coords);
      result = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      saveCookie({
        label: "geolocation",
        content: [position.coords.latitude, position.coords.longitude],
      });
    });
  }

  console.log("position(1)", result);
  return result;
};

export const getCookie = ({ label }) => {
  let cookies = document.cookie;

  cookies = cookies.split(";");

  for (let cookie of cookies) {
    if (cookie.indexOf(label) !== -1) {
      let actual = cookie.split("=");

      return actual[1].trim();
    }
  }

  return false;
};

const setOrder = (name, field, type, order) => {
  const _data = {
    field: field,
    type: type,
    order: order,
  };

  localStorage.setItem("_order" + name, JSON.stringify(_data));
};

const getOrder = (name) => {
  return JSON.parse(localStorage.getItem("_order" + name))
    ? JSON.parse(localStorage.getItem("_order" + name))
    : { order: "asc" };
};

export const DefaultOrder = (name, arr, setArr) => {
  const _order = getOrder(name);
  sortArrObj(name, _order.field, _order.type, arr, setArr, false);
};

export const sortArrObj = (name, key, type, arr, setArr, update = true) => {
  let _arr = [...arr];
  //  console.log(arr)
  _arr = _arr.sort((a, b) => {
    update &&
      setOrder(
        name,
        key,
        type,
        getOrder(name).order === "desc" ? "asc" : "desc"
      );
    if (type === "string") {
      if (getOrder(name).order === "asc") {
        return sortByString(a, b, key);
      } else {
        return sortByString(b, a, key);
      }
    } else if (type === "numeric") {
      if (getOrder(name).order === "asc") {
        return sortByNumber(a, b, key);
      } else {
        return sortByNumber(b, a, key);
      }
    } else {
      if (getOrder(name).order === "asc") {
        return compareDate(new Date(a[key]), new Date(b[key])) ? 1 : -1;
      } else {
        return compareDate(new Date(b[key]), new Date(a[key])) ? 1 : -1;
      }
    }
  });
  //console.log(_arr)
  setArr(_arr);
};

export const saveCookie = ({ label, content }) => {
  console.log("saveCookie", { label, content });
  createNewCookie({ label, content });

  return false;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const sortByString = (a, b, key) => {
  let aVal = a[key] || "z",
    bVal = b[key] || "z";

  aVal = aVal.toUpperCase();
  bVal = bVal.toUpperCase();

  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};

export const sortByNumber = (a, b, key) => {
  let aVal = a[key] || 0,
    bVal = b[key] || 0;

  aVal = parseFloat(aVal.toString().replace(/\D/g, ""));
  bVal = parseFloat(bVal.toString().replace(/\D/g, ""));

  if (aVal < bVal) {
    return -1;
  }
  if (aVal > bVal) {
    return 1;
  }
  return 0;
};

export const operacaoList = [
  {
    descricao: "Contrato Novo",
    value: "CONTRATO NOVO",
  },
  {
    descricao: "Refinanciamento",
    value: "REFINANCIAMENTO",
  },
  {
    descricao: "Portabilidade",
    value: "PORTABILIDADE",
  },
  {
    descricao: "Cartão",
    value: "CARTAO",
  },
  {
    descricao: "Crédito Pessoal",
    value: "CREDITO PESSOAL",
  },
  {
    descricao: "FGTS",
    value: "FGTS",
  },
];

export const usuarios = [
  "cliente",
  "agente",
  "operador",
  "operador parceiro",
  "gerente",
  "administrador",
  "sistema",
];

export const getIdCode = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const contextMenuOptions = [
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Reapresentar TED",
    icon: "mdi mdi mdi-format-list-checks",
    action: "reapresentarted",
    colorClass: "text-primary",
    enabled: true,
    // positions: ["pago", "aprovada banco", "enviada para banco"],
    positions: ["pago"],
    order: 0,

    tip: "Reapresentar TED de pagamento do cliente",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      console.log("general.reapresentarted(props)", props.valor_liquido);

      const _posicoesPort = props?.posicoes?.historico || []
      if (bancoFornecedor() == "inbursa") {
        return false
      }
      else
        return enabled
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Enviar proposta para Assinatura",
    icon: "mdi mdi-email-outline",
    action: "enviarParaAssinatura",
    colorClass: "text-success",
    positions: [
      "cadastrada no banco",
      "erro ao assinar ccb",
      "aprovada",
      "portabilidade autorizada",
      "aguardando saldo",
      "simulacao",
      "aprovada ctc",
    ],
    enabled: true,
    multSelect: true,
    tip: "Enviar proposta para o Cliente assinar",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      console.log("general.enviarParaAssinatura(props)", props.valor_liquido);
      // (enabled ||
      //   (props?.posicao?.toLowerCase() == "simulacao" && props.gravou_documentos && props?.operacao?.toLowerCase() != 'portabilidade')) &&
      // (!props.valor_liquido || props.valor_liquido > 0)
      const _posicoesPort = props?.posicoes?.historico || [];
      if (bancoFornecedor() == "inbursa") {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          const positions = ["aprovada"];
          return (
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else
          return (
            enabled &&
            props?.operacao?.toLowerCase() !== "refinanciamento" &&
            ["portabilidade autorizada", "aprovada"].indexOf(
              props?.posicao?.toLowerCase()
            ) >= 0 &&
            !_posicoesPort?.some((posicao) =>
              [
                "assinando ccb",
                "assinando ccb port",
                "proposta assinada",
                "proposta port assinada",
              ].includes(posicao)
            )
          );
      } else {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao?.toLowerCase();
          const positions = ["aprovada ctc"];
          const _enabled = positions.indexOf(posicao?.toLowerCase()) >= 0 && ["administrador", "operador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0
          console.log("general.enviarParaAssinatura(props-x)", { posicao, positions, userTipo: props.user?.tipo?.toLowerCase(), _enabled });
          return _enabled
        }
        else
          return (
            ((["simulacao", "aprovada"].indexOf(props?.posicao?.toLowerCase()) >= 0 &&
              props.gravou_documentos &&
              props?.operacao?.toLowerCase() != "portabilidade") ||
              (props.gravou_documentos &&
                _posicoesPort?.some((posicao) =>
                  ["aprovada ctc"].includes(posicao)
                ) &&
                !_posicoesPort?.some((posicao) =>
                  [
                    "assinando ccb",
                    "assinando ccb port",
                    "proposta assinada",
                    "proposta port assinada",
                  ].includes(posicao)
                ) &&
                props?.fluxo != "padrao")) &&
            props.valor_liquido > 0
          );
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Solicitar Averbação",
    icon: "mdi mdi-skew-more",
    action: "solicitarAverbacao",
    colorClass: "text-primary",
    enabled: true,
    positions: [
      "proposta assinada",
      "averbacao solicitada",
      "aguardando deferimento",
      "saldo pago",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],

    multSelect: true,
    tip: "Solicitar Averbação da Proposta",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (bancoFornecedor() == "cef") {
        return false
      }
      else if (bancoFornecedor() == "inbursa") {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          const positions = ["saldo pago"];
          return (
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else
          return (
            enabled && ["saldo pago"].indexOf(props.posicao?.toLowerCase()) >= 0
          );
      } else {
        // console.log("general.contextMenuOptions(solicitarAverbacao): ", { props, filtros, positions: this?.positions })
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          const positions = [
            "averbacao solicitada",
            "aguardando deferimento",
            "aguardando refin",
          ];
          return (
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else
          return (
            props.tipo_convenio != "ORIGINAL" &&
            ((props.tipo_convenio?.toLowerCase() == "inss" &&
              ["aguardando refin"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
              ["administrador", "operador"].indexOf(
                props?.user?.tipo.toLowerCase()
              ) >= 0) ||
              (props.tipo_convenio?.toLowerCase() != "inss" &&
                ["proposta port assinada"].indexOf(
                  props.posicao?.toLowerCase()
                ) >= 0 &&
                ["administrador", "operador"].indexOf(
                  props?.user?.tipo.toLowerCase()
                ) >= 0) ||
              enabled)
          );
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Enviar para Banco - Cadastro",
    icon: "mdi mdi-skew-more",
    action: "enviarParaBancoCadastro",
    colorClass: "text-primary",
    enabled: true,
    multSelect: true,
    positions: [
      "em analise",
      "simulacao",
      "proposta port assinada",
      "proposta assinada",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Enviar proposta para cadastro no banco",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      if (["inbursa", "cef"].indexOf(bancoFornecedor()) >= 0) {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao?.toLowerCase();
          const conformidade =
            props?.conformidadeapv?.toString()?.toLowerCase() == "sim";
          const positions = [
            "proposta port assinada",
            "proposta assinada",
            "aprovada",
            "conformidade aprovada",
          ];
          const _result =
            conformidade &&
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0;
          console.log("general.contextMenuOptions(enviarParaBanco): ", {
            _result,
            posicao,
            positions,
            tipoUser: props.user?.tipo,
          });
          return _result;
        }
        return (
          _posicoesPort?.indexOf("enviada para banco") < 0 &&
          (_posicoesPort?.indexOf("conformidade aprovada") >= 0 ||
            props?.conformidadeapv?.toString()?.toLowerCase() == "sim")
        );
      } else return props.tipo_convenio == "SICOOB" && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Reenviar para Banco - Cadastro",
    icon: "mdi mdi-skew-more",
    action: "reenviarParaBancoCadastro",
    colorClass: "text-primary",
    enabled: true,
    multSelect: true,
    // positions: ["enviada para banco"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Reenviar proposta para cadastro no banco",
    order: 1.55,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      return false;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Análise Automática",
    icon: "mdi mdi-checkbox-marked-outline",
    action: "analiseAutomatica",
    colorClass: "text-primary",
    enabled: true,
    multSelect: true,
    positionsEx: [
      "pendente",
      "pago",
      "cancelada",
      "bloqueada",
      "aguardando pagamento",
      "inutilizada",
      "negada",
    ],
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Faz análise automática da proposta",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      const _bancoFornecedor = bancoFornecedor();
      if (_bancoFornecedor == "inbursa") {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao?.toLowerCase();
          const positions = ["simulacao"];
          const _result =
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador", "operador parceiro"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0;
          console.log("general.contextMenuOptions(analiseAutomatica): ", {
            _result,
            posicao,
            positions,
            tipoUser: props.user?.tipo,
          });
          return _result;
        } else
          return (
            enabled &&
            ["simulacao"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador", "operador parceiro"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
      } else {
        return (
          enabled &&
          _posicoesPort?.indexOf("aprovada") < 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Solicitar Saldo Devedor",
    icon: "mdi mdi-bank",
    action: "solicitarSaldoDevedor",
    colorClass: "text-primary",
    enabled: true,
    positions: [
      "erro ao solicitar saldo",
      "aprovada banco",
      "conformidade aprovada",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    multSelect: true,
    tip: "Solicitar Saldo devedor da Proposta",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      console.log("general.contextMenuOptions(solicitarSaldoDevedor): ", {
        props,
      });
      if (bancoFornecedor() == "inbursa") {
        // return (enabled && props?.operacao == "PORTABILIDADE" && ["aprovada banco", 'erro ao solicitar saldo'].indexOf(props.posicao?.toLowerCase()) >= 0)
        return false;
      } else {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          const positions = ["erro ao solicitar saldo", "aprovada"];
          return (
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else {
          if (bancoFornecedor() == "inbursa") {
            const posicao = props?.posicao || props?.detalhes?.posicao;
            const positions = [
              "proposta port assinada",
              "proposta assinada",
              "erro ao solicitar saldo",
            ];
            return (
              props?.operacao == "PORTABILIDADE" &&
              positions.indexOf(posicao?.toLowerCase()) >= 0 &&
              ["administrador", "operador"].indexOf(
                props.user?.tipo?.toLowerCase()
              ) >= 0
            );
          } else {
            const posicao = props?.posicao || props?.detalhes?.posicao;
            const positions = [
              "erro ao solicitar saldo",
              "conformidade aprovada",
              "aprovada",
            ];
            if (_posicoesPort?.indexOf("saldo recebido") >= 0) return false;

            return (
              props?.operacao == "PORTABILIDADE" &&
              positions.indexOf(posicao?.toLowerCase()) >= 0 &&
              ["administrador", "operador"].indexOf(
                props.user?.tipo?.toLowerCase()
              ) >= 0 &&
              props.fluxo != "novaform"
            );
          }
        }
      }
    },
  },
  // {
  //   disableUser: ["AGENTE", "CLIENTE"],
  //   title: "Solicitar Autorização de Portabilidade",
  //   icon: "mdi mdi-checkbox-marked-outline",
  //   action: "solicitarAutorizacaoPortabilidade",
  //   colorClass: "text-primary",
  //   enabled: true,
  //   positions: ["simulacao"],
  //   tip: "Solicitar Autorização de Portabilidade",
  //   id: getIdCode(),
  //   enableFunction: (props, enabled, filtros) => {
  //     console.log("general.contextMenuOptions(solicitarAutorizacaoPortabilidade): ", props)
  //     return (
  //       (["simulacao"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
  //         props.tipo_convenio == "INSS" &&
  //         props.operacao == 'PORTABILIDADE' &&
  //         props.gravou_documentos
  //       ) && enabled
  //     );
  //   },
  // },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Checar Autorização",
    icon: "mdi mdi-checkbox-marked-outline",
    action: "autorizarPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["autorizando portabilidade"],
    tip: "Checar autorização de portabilidade",
    order: 0,
    multSelect: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      //-- Retirado pois será autorizado na assinatura(diogo 25.09.2024)
      return false;
      // console.log("general.contextMenuOptions(autorizarPortabilidade): ", { props, filtros, positions: this?.positions })
      if (bancoFornecedor() == "inbursa") return false;

      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = ["simulacao", "autorizando portabilidade"];
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else
        return (
          ["simulacao", "autorizando portabilidade"].indexOf(
            props.posicao?.toLowerCase()
          ) >= 0 &&
          props.tipo_convenio == "INSS" &&
          props.operacao == "PORTABILIDADE" &&
          props.gravou_documentos
        );
    },
  },
  {
    title: "Consultar Solicitação de Saldo",
    icon: "mdi mdi-file-find",
    action: "consultarSolicitacaoSaldo",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando saldo"],
    tipoUsuarioEx: ["agente", "cliente"],
    multSelect: true,
    tip: "Consultar Solicitação de Saldo Devedor",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesProp = props?.posicoes?.historico;
      console.log("general.contextMenuOptions(consultarSolicitacaoSaldo): ", {
        props,
        filtros,
        positions: this?.positions,
        _posicoesProp,
      });
      console.log("general.contextMenuOptions(consultarSolicitacaoSaldo-0): ", {
        _posicoesProp,
      });
      if (bancoFornecedor() == "inbursa") {
        if (
          _posicoesProp?.indexOf("saldo recebido") >= 0 ||
          props?.operacao != "PORTABILIDADE"
        )
          return false;

        return (
          enabled ||
          (_posicoesProp?.some((posicao) =>
            ["aguardando saldo", "aprovada banco"].includes(posicao)
          ) &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0)
        );
        // return props?.operacao?.toLowerCase() == 'portabilidade' && _posicoesProp?.indexOf("enviada para banco") >= 0 && _posicoesProp?.indexOf("aguardando saldo") >= 0 &&
        // _posicoesProp?.indexOf("saldo recebido") < 0 && _posicoesProp?.indexOf("cancelada") < 0
      }
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = [
          "aguardando saldo",
          "proposta port assinada",
          "assinando ccb port",
        ];
        console.log(
          "general.contextMenuOptions(consultarSolicitacaoSaldo-1): ",
          { filtros }
        );
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else {
        if (
          _posicoesProp?.indexOf("saldo recebido") >= 0 ||
          props?.operacao != "PORTABILIDADE"
        )
          return false;

        return (
          enabled ||
          (_posicoesProp?.indexOf("aguardando saldo") >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0)
        );
      }

      // (props.fluxo == "novo" && props.posicao?.toLowerCase() == "proposta port assinada" && _posicoesProp?.indexOf("aprovada ctc") >= 0 && ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Resolicitar Saldo Devedor",
    icon: "mdi mdi-bank",
    action: "solicitarSaldoDevedor",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],

    tip: "Refazer Solicitação de Saldo devedor da Proposta",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (bancoFornecedor() == "inbursa") return false;
      return enabled;
    },
  },

  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Solicitar Pagto Saldo",
    icon: "mdi mdi-cached",
    action: "pagarPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro",],
    multSelect: true,
    tip: "Solicitar o Pagamento do Saldo Devedor",
    order: 0,
    id: getIdCode(),
    checklist: true,
    enableFunction: (props, enabled, filtros) => {
      const _posicoesProp = props?.posicoes?.historico;
      console.log("general.contextMenuOptions(pagarPortabilidade): ", { props, filtros, _posicoesProp, enabled })
      if (bancoFornecedor() == "inbursa") {
        return props?.posicao?.toLowerCase() == "saldo aprovado";
      } else {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao?.toLowerCase();
          const convenio = filtros?.convenio?.toLowerCase();
          const _averbada = posicao === "portabilidade averbada" && convenio !== "inss"
          const _aprovada = posicao === "conformidade aprovada"
          const _user = ["administrador", "operador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0

          const _result = ((_averbada || _aprovada) && _user);

          console.log("general.contextMenuOptions(pagarPortabilidade-0): ", { posicao, convenio, _aprovada, _user, _result })
          return _result
        } else {
          console.log("general.contextMenuOptions(pagarPortabilidade): ", { _posicoesProp, convenio: props.tipo_convenio })
          const posicao = props?.posicao?.toLowerCase();
          return (
            enabled &&
            (
              (props.posicao?.toLowerCase() == "portabilidade averbada" && props.tipo_convenio != "INSS") ||
              (posicao === "conformidade aprovada" && _posicoesProp.indexOf("saldo formalizado") >= 0 && props.tipo_convenio == "INSS")
            )
          );
        }
      }
    },
  },
  {
    disableUser: ["CLIENTE", "OPERADOR PARCEIRO"],
    title: "Consultar Status Portabilidade",
    icon: "mdi mdi-file-find",
    action: "consultarStatusPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando pagto saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    multSelect: true,
    tip: "Consultar Status da Portabilidade no Banco",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      // console.log("general.contextMenuOptions(consultarStatusPortabilidade): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = ["aguardando pagto saldo"];
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else return enabled;
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Gerar Refinanciamento Automático",
    icon: "mdi mdi-file-find",
    action: "gerarPropostaRefinPort",
    colorClass: "text-primary",
    enabled: true,
    positions: ["portabilidade averbada"],
    tipoUsuarioEx: ["agente", "cliente"],
    multSelect: true,
    tip: "Gerar Proposta de Refinanciamento da(s) Portabilidade(s)",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const historico = props?.posicoes?.historico || [];
      const _user = ["administrador", "operador", "operador parceiro"].indexOf(props.user?.tipo?.toLowerCase()) >= 0

      if (bancoFornecedor() == "inbursa") return false;

      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = ["portabilidade averbada"];
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador", "operador parceiro"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else
        return enabled;
    },
  },
  {
    title: "Gerar Refinanciamento Automático",
    icon: "mdi mdi-file-find",
    action: "gerarRefinPort",
    colorClass: "text-primary",
    enabled: true,
    tipoUsuarioEx: ["agente", "cliente"],
    multSelect: true,
    positionsEx: ["cancelada"],
    tip: "Gerar Proposta de Refinanciamento (Sem Contrato no Banco)",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const historico = props?.posicoes?.historico || [];
      const _user = ["administrador", "operador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0

      if (bancoFornecedor() == "inbursa") {
        return enabled && props.operacao === 'PORTABILIDADE' && props?.fluxo == "novo" && !props.propostarefid && !historico.some(posicao => posicao === 'enviada para banco')

      }

      return enabled && props?.operacao == "PORTABILIDADE" && _user && historico.some(posicao => ["aprovada"].includes(posicao)) && !props.propostarefid;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Simular Proposta Refin",
    icon: "mdi mdi-send",
    action: "assinarRefinPort",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando refin"],
    tipoUsuarioEx: ["agente", "cliente"],

    tip: "Enviar Proposta de Refin da Port para Assinatura",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (bancoFornecedor() == "inbursa") return false;
      return enabled;
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Simular Portabilidade",
    icon: "mdi mdi-calculator",
    action: "simularPortabilidade",
    colorClass: "text-primary",
    enabled: true,
    positions: ["saldo recebido"],
    tipoUsuarioEx: ["cliente", "agente"],
    tip: "Simulação da Portabilidade após chegada do saldo devedor",
    order: -10,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      // console.log("general.contextMenuOptions(consultarStatusPortabilidade): ", { props, filtros, positions: this?.positions })
      const _temPermissao = temPermissao(props.user, "simularportabilidade");
      console.log("general.contextMenuOptions(simularPortabilidade): ", {
        _temPermissao,
        props,
      });
      if (bancoFornecedor() == "inbursa")
        return (
          ["saldo recebido"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
          (_temPermissao ||
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0)
        );
      else {
        const _aprovada = _posicoesPort?.some(posicao => ["conformidade aprovada", "analise esteira", "aguardando pagto saldo", "pago"].includes(posicao))
        return (
          !_aprovada &&
          (_posicoesPort?.indexOf("saldo recebido") >= 0 || props.posicao?.toLowerCase() == "saldo recebido") &&
          (_temPermissao || ["administrador", "operador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
        );
      }

      //-- Diogo voltar(25.09.2024)
      return enabled && props.fluxo != "padrao";
    },
  },
  // {
  //   disableUser: ["CLIENTE"],
  //   title: "Nova Formalização",
  //   icon: "mdi mdi-content-copy",
  //   action: "novaFormalizacao",
  //   colorClass: "text-primary",
  //   enabled: true,
  //   // borderBottom: true,
  //   tipoUsuarioEx: ["agente", "cliente"],
  //   positions: ["saldo recebido"],
  //   tip: "Espelhar/Copiar a proposta atual, gerando uma nova formalização",
  //   order: 0,
  //   id: getIdCode(),
  //   enableFunction: (props, enabled) => {
  //     const _posicoesPort = props?.posicoes?.historico
  //     const userTipo = props.user?.tipo?.toLowerCase();
  //     const posicoesValidas = ["proposta assinada", "proposta port assinada"];
  //     const tipoUsuariosValidos = ["administrador", "operador"];

  //     return (enabled && _posicoesPort?.some(posicao => posicoesValidas.includes(posicao)) && tipoUsuariosValidos.includes(userTipo) && props.fluxo === "novo");
  //   },
  // },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Averbação Manual",
    icon: "mdi mdi-stamper",
    action: "averbar",
    colorClass: "text-primary",
    enabled: true,
    positions: [
      "proposta assinada",
      "averbacao solicitada",
      "aguardando deferimento",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],

    tip: "Averbar proposta",
    order: 0,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      // console.log("general.contextMenuOptions - enableFunction: ", props)
      if (bancoFornecedor() == 'cef')
        return false
      else if (bancoFornecedor() == "inbursa") {
        return false; //-- Habilitar para inbursa na faze pós saldo pago
      } else
        return (
          props.tipo_convenio != "ORIGINAL" &&
          ((props.tipo_convenio?.toLowerCase() == "inss" &&
            ["aguardando refin"].indexOf(props.posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props?.user?.tipo.toLowerCase()
            ) >= 0) ||
            (props.tipo_convenio?.toLowerCase() != "inss" &&
              ["proposta port assinada"].indexOf(
                props.posicao?.toLowerCase()
              ) >= 0 &&
              ["administrador", "operador"].indexOf(
                props?.user?.tipo.toLowerCase()
              ) >= 0) ||
            enabled)
        );
    },

    // enableFunction: (props, enabled) => {
    //   console.log("props", props.user);
    //   return (
    //     enabled &&
    //     props.tipo_convenio != "ORIGINAL" &&
    //     props.pagamento == "AUTOMATICO" &&
    //     ((props.tipo_convenio?.toLowerCase() == "inss" &&
    //       ["aguardando refin", 'proposta assinada'].indexOf(props.posicao?.toLowerCase()) > 0) ||
    //       (props.tipo_convenio?.toLowerCase() != "inss" &&
    //         ["proposta port assinada", 'proposta assinada'].indexOf(props.posicao?.toLowerCase()) > 0)
    //       // || enabled
    //     ) &&
    //     (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    //   );
    // },
  },
  {
    title: "Checar Assinatura",
    icon: "mdi mdi-pencil",
    action: "informarAssinatura",
    colorClass: "text-primary",
    enabled: true,
    // borderBottom: true,
    positions: [
      "assinando ccb",
      "assinando ccb refinport",
      "assinando ccb port",
      "saldo recebido",
    ],
    multSelect: true,
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Verificar se proposta foi assinada",
    order: -1,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      // console.log("general.contextMenuOptions(informarAssinatura): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao?.toLowerCase();
        const positions = [
          "assinando ccb",
          "assinando ccb refinport",
          "assinando ccb port",
        ];
        return (
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0 && positions?.indexOf(posicao) >= 0
        );
      } else
        return (
          enabled &&
          !_posicoesPort?.some((posicao) =>
            ["proposta port assinada", "proposta assinada"].includes(posicao)
          )
        );
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Enviar para Banco",
    icon: "mdi mdi-arrow-right",
    action: "enviarParaBanco",
    colorClass: "text-primary",
    enabled: true,
    positions: ["averbada", "negada"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Enviar proposta com dossiê para aprovação e pagamento no banco",
    order: 0,
    multSelect: true,
    id: getIdCode(),
    checklist: true,
    enableFunction: (props, enabled, filtros) => {
      console.log("enableFuncion", props, enabled);
      // console.log("general.contextMenuOptions(enviarParaBanco): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = ["averbada", "negada"];
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else
        return (
          (["ORIGINAL", "SICOOB"].indexOf(props.tipo_convenio) >= 0 &&
            props.posicao?.toLowerCase() == "proposta assinada") ||
          enabled
        );
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Status Banco",
    icon: "mdi mdi-file-find",
    action: "consultarStatusBanco",
    colorClass: "text-primary",
    enabled: true,
    // positions: ["conformidade aprovada", "aguardando pagamento", 'enviada para banco', 'saldo solicitado', 'aguardando saldo', 'saldo recebido',
    //   'aguardando pagto saldo', 'aprovada banco', 'averbacao solicitada'],
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Consultar status da Proposta no Banco",
    order: 1.5,
    multSelect: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      const positionsInbursa = [
        "enviada para banco",
        "aguardando refin",
        "saldo solicitado",
        "aguardando saldo",
        "saldo recebido",
        "aguardando pagto saldo",
        "aprovada banco",
        "averbacao solicitada",
        "portabilidade averbada",
        "aguardando refin",
        "pago",
      ];
      console.log("general.contextMenuOptions(consultarStatusBanco): ", {
        enabled,
        props,
        filtros,
        positions: this?.positions,
        _posicoesPort,
        positionsInbursa,
      });
      if (["inbursa", "cef"].indexOf(bancoFornecedor()) >= 0) {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          return (
            positionsInbursa.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador", "operador parceiro"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else
          return (
            enabled &&
            _posicoesPort?.some((posicao) =>
              positionsInbursa.includes(posicao)
            ) &&
            ["administrador", "operador", "operador parceiro"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
      } else {
        if (props?.multSelect?.length > 1) {
          const posicao = filtros?.posicao;
          const positions = ["aguardando pagamento"];
          return (
            positions.indexOf(posicao?.toLowerCase()) >= 0 &&
            ["administrador", "operador"].indexOf(
              props.user?.tipo?.toLowerCase()
            ) >= 0
          );
        } else
          return (
            (!props.pagamento || props.pagamento == "AUTOMATICO") &&
            enabled &&
            ["aguardando pagamento"].indexOf(props.posicao?.toLowerCase()) >= 0
          );
      }
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Averbação",
    icon: "mdi mdi-file-find",
    action: "consultarAverbacao",
    colorClass: "text-primary",
    enabled: true,
    positions: ["averbada", "averbacao solicitada"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    order: 0,
    tip: "Consultar Averbação(Gerar Comprovante)",
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (bancoFornecedor() == "inbursa") return false;
      return enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Reenviar dossiê para Banco",
    icon: "mdi mdi-arrow-right",
    action: "reenviarDossie",
    colorClass: "text-primary",
    multSelect: true,
    enabled: true,
    positions: [
      "aguardando pagamento",
      "aguardando pagto saldo",
      "pago",
      "portabilidade averbada",
      "aguardando refin",
    ],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Reenviar dossiê do contrato para o Banco",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (bancoFornecedor() == "inbursa") return false;
      // console.log("general.contextMenuOptions(pendenciar): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        const posicao = filtros?.posicao;
        const positions = [
          "aguardando pagamento",
          "aguardando pagto saldo",
          "pago",
          "portabilidade averbada",
          "aguardando refin",
        ];
        return (
          positions.indexOf(posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else
        return (!props.pagamento || props.pagamento == "AUTOMATICO") && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Reenviar contrato para Banco",
    icon: "mdi mdi-arrow-right",
    action: "reenviarParaBanco",
    colorClass: "text-primary",
    enabled: true,
    positions: ["aguardando pagamento", "aguardando pagto saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Reenviar contrato para o Banco",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (!props.pagamento || props.pagamento == "AUTOMATICO") && enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Informar Pagamento",
    icon: "mdi mdi-cash-100",
    action: "informarPagamento",
    colorClass: "text-primary",
    enabled: true,
    // borderBottom: true,
    positions: ["aguardando pagamento"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    tip: "Informar pagamento da proposta pelo banco",
    order: 2,
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Pendenciar",
    icon: "mdi mdi-clock",
    action: "pendenciar",
    // action: "analiseAutomatica",
    enabled: true,
    colorClass: "text-primary",
    multSelect: true,
    positionsEx: [
      "pendente",
      "pago",
      "cancelada",
      "bloqueada",
      "aguardando pagamento",
      "negada",
    ],
    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Pendenciar proposta",
    order: 2,
    borderTop: true,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      if (props?.multSelect?.length > 1) {
        return (
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else {
        const _positionsExOpParceiro = [
          "cancelada",
          "pago",
          "negada",
          "aguardando pagamento",
          "aguardando refin",
          "aguardando pagto saldo",
          "bloqueada",
          "aguardando pagamento de saldo",
          "portabilidade averbada",
          "averbada",
          "averbacao solicitada",
        ];

        console.log("general.contextMenuOptions(pendenciar): ", {
          enabled,
          tipoUsuario: props?.user?.tipo?.toLowerCase(),
          posicao: props.posicao.toLowerCase(),
        });
        if (props?.user?.tipo?.toLowerCase() == "operador parceiro")
          return (
            enabled &&
            _positionsExOpParceiro.indexOf(props.posicao.toLowerCase()) < 0
          );
        else
          return (
            enabled &&
            (props.posicao.toLowerCase() != "aguardando refin" ||
              ["administrador", "operador"].indexOf(
                props.user?.tipo?.toLowerCase()
              ) >= 0)
          );
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Despendenciar",
    icon: "mdi mdi-clock",
    action: "despendenciar",
    enabled: true,
    colorClass: "text-primary",
    positions: ["pendente"],
    tipoUsuarioEx: ["agente", "operador parceiro"],
    tip: "Despendenciar proposta",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      console.log("general.enableFunction(despendenciar): ", {
        enabled,
        pendenciatmp: props.pendenciatmp,
      });
      // return (enabled && props.pendenciatmp)
      return enabled;
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO"],
    title: "Bloquear",
    icon: "mdi mdi-lock",
    action: "bloquear",
    enabled: true,
    colorClass: "text-danger",
    positionsEx: [
      "bloqueada",
      "pago",
      "cancelada",
      "simulacao",
      "aguardando pagamento",
      "proposta assinada",
      "negada",
    ],
    tipoUsuarioEx: ["agente", "cliente"],
    order: 2,
    tip: "Bloquear proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (
        enabled &&
        (props.posicao.toLowerCase() != "aguardando refin" ||
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0)
      );
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO"],
    title: "Desbloquear",
    icon: "mdi mdi-lock-open",
    action: "desbloquear",
    colorClass: "text-success",
    enabled: true,
    borderBottom: true,
    positions: ["bloqueada"],
    tipoUsuarioEx: ["agente", "operador", "operador parceiro"],
    tip: "Desbloquear proposta",
    order: 2,
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Cancelar",
    icon: "mdi mdi-close-circle",
    action: "cancelar",
    colorClass: "text-danger",
    multSelect: true,
    enabled: true,
    borderTop: true,
    positionsEx: [
      "cancelada",
      "pago",
      "negada",
      "aguardando pagamento",
      "aguardando refin",
      "aguardando pagto saldo",
      "bloqueada",
      "aguardando pagamento de saldo",
      "portabilidade averbada",
      "averbada",
      "averbacao solicitada",
    ],

    tipoUsuarioEx: ["agente", "cliente"],
    tip: "Cancelar proposta",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      console.log("general.enableFunction: ", { props, enabled });
      // console.log("general.contextMenuOptions(cancelar): ", { props, filtros, positions: this?.positions })
      if (props?.multSelect?.length > 1) {
        return (
          ["administrador", "operador"].indexOf(
            props.user?.tipo?.toLowerCase()
          ) >= 0
        );
      } else
        return (
          enabled ||
          (props?.posicao?.toLowerCase() != "bloqueada" &&
            props?.posicao?.toLowerCase() != "cancelada" &&
            ["administrador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
        );
    },
  },
  //-- 27.12.2024 - retirado a pedido do cliente(Alexandre)
  // {
  //   disableUser: ["AGENTE", "CLIENTE", "OPERADOR", "OPERADOR PARCEIRO"],
  //   title: "Inutilizar",
  //   icon: "mdi mdi-shuffle-disabled",
  //   action: "inutilizar",
  //   colorClass: "text-danger",
  //   enabled: true,
  //   borderTop: true,
  //   positionsEx: ["inutilizada", "pago", "negada", "aguardando refin"],
  //   tip: "Inutilizar proposta(A Proposta não ficará mais visível)",
  //   order: 2,
  //   id: getIdCode(),
  //   enableFunction: (props, enabled) => {
  //     return (
  //       enabled ||
  //       ["administrador"].indexOf(props.user?.tipo?.toLowerCase()) >= 0
  //     );
  //   },
  // },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Alterar Valores",
    icon: "mdi mdi-calculator",
    action: "simulation",
    enabled: true,
    borderBottom: true,
    colorClass: "text-success",
    positions: [
      "simulacao",
      "portabilidade autorizada",
      "autorizando portabilidade",
      "aguardando saldo",
    ],
    // positionsEx: [
    //   "bloqueada",
    //   "pago",
    //   "cancelada",
    //   "aguardando pagamento",
    //   "proposta assinada",
    //   "proposta port assinada",
    //   "negada",
    //   "saldo recebido",
    // ],
    tip: "Alterar Valores da Proposta",
    order: 2,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return (
        enabled ||
        ["administrador", "operador"].indexOf(
          props.user?.tipo?.toLowerCase()
        ) >= 0
      );
    },
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Enviar proposta para o Cliente",
    icon: "mdi mdi-email-outline",
    action: "emailCliente",
    colorClass: "text-success",
    positions: [
      "assinando ccb",
      "assinando ccb port",
      "assinando ccb refinport",
      "cadastrada no banco",
    ],
    positionsEx: ["cadastrada no banco"],
    enabled: true,
    tip: "Enviar proposta para o Cliente",
    order: 0,
    id: getIdCode(),
  },
  // {
  //   disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
  //   title: "Enviar proposta para o Cliente(Whats)",
  //   icon: "mdi mdi-whatsapp",
  //   action: "enviarparaassinaturawhats",
  //   colorClass: "text-success",
  //   positions: [
  //     "assinando ccb",
  //     "assinando ccb port",
  //     "assinando ccb refinport",
  //     "erro ao assinar ccb",
  //   ],
  //   enabled: true,
  //   tip: "Enviar proposta para o Cliente(Token via WhatsApp)",
  //   order: 0,
  //   id: getIdCode(),
  // },
  // {
  //   disableUser: ["AGENTE", "CLIENTE"],
  //   title: "Enviar Email",
  //   icon: "mdi mdi-email-outline",
  //   action: "enviarEmail",
  //   colorClass: "text-success",
  //   enabled: true,
  //   borderBottom: true,
  //   positionsEx: ["cancelada", "bloqueada", "negada"],

  //   tip: "Enviar email referente a Proposta",
  //   id: getIdCode(),
  //   enableFunction: (props, enabled) => {
  //     return (enabled && (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0))
  //   },

  // },
  {
    disableUser: ["CLIENTE"],
    title: "Documentos",
    icon: "mdi mdi-file-document",
    action: "gerirDocs",
    enabled: true,
    // borderTop: true,
    colorClass: "text-success",
    // positionsEx: ["cancelada"],
    // tipoUsuarioEx: ["agente"],
    tip: "Gerir documentos da proposta",
    order: 3,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      if (bancoFornecedor() == "inbursa" && props.operacao == "refinanciamento" && props.propostarefid) //-- Inbursa não permite envio de documentos para refin de port
        return false
      else
        return enabled
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Margem",
    icon: "mdi mdi-cart-plus",
    action: "consultarMargem",
    colorClass: "text-primary",
    borderTop: true,
    enabled: true,
    tip: "Consultar margem consignável do cliente",
    order: 4,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return props.tipo_convenio != "ORIGINAL" && enabled;
    },
  },
  {
    title: "Alterar Simulação",
    icon: "mdi mdi-update",
    action: "alterarSimulacao",
    colorClass: "text-primary",
    enabled: true,
    tip: "Alterar Simulação do Banco Original",
    order: 5,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return props.tipo_convenio == "ORIGINAL" && enabled;
    },
  },
  {
    disableUser: ["CLIENTE"],
    title: "Consultar Restrições",
    icon: "mdi mdi-chemical-weapon",
    action: "consultarRestricoes",
    colorClass: "text-primary",
    enabled: true,

    tip: "Consultar restrições do cliente no banco",
    order: 4,
    id: getIdCode(),
  },
  {
    title: "Status",
    icon: "mdi mdi-white-balance-incandescent",
    action: "status",
    colorClass: "text-primary",
    enabled: true,
    // borderBottom: true,
    tip: "Visualização do Status da Proposta",
    order: 3,
    id: getIdCode(),
  },
  {
    title: "Auditoria de Assinatura",
    icon: "mdi mdi-filmstrip",
    action: "auditAssinatura",
    colorClass: "text-primary",
    positionsEx: ["inutilizada"],
    enabled: true,
    borderBottom: true,
    tip: "Visualização da Auditoria de Assinatura",
    order: 9,
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      return enabled && props?.posicao?.toLowerCase() != "simulacao";
    },
  },
  {
    title: "Observações",
    icon: "mdi mdi mdi-note",
    action: "obs",
    colorClass: "text-primary",
    enabled: true,
    // positionsEx: ["cancelada", "bloqueada", "negada"],

    tip: "Observações da Proposta",
    order: 8,
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Conformidade",
    icon: "mdi mdi mdi-format-list-checks",
    action: "conformidade",
    colorClass: "text-primary",
    enabled: true,
    // positionsEx: ["cancelada", "bloqueada", "negada"],
    positionsEx: ["bloqueada", "negada"],
    order: 1.6,
    tip: "Conformidade da Proposta",
    id: getIdCode(),
    enableFunction: (props, enabled) => {
      const _posicoesPort = props?.posicoes?.historico || [];
      const _someInbursa = _posicoesPort?.some((posicao) =>
        ["enviada para banco", "aprovada banco"]?.includes(posicao)
      );
      console.log("general.Conformidade(enableFunction)", {
        _posicoesPort,
        _someInbursa,
      });

      if (bancoFornecedor() == "inbursa") {
        return (
          enabled &&
          !["cancelada", "inutilizada", "bloqueada"].includes(
            props?.posicao?.toLowerCase()
          )
        );

        // return enabled && !(["cancelada", "inutilizada", "bloqueada"].indexOf(props?.posicao?.toLowerCase()))
        // return enabled
      }
      //-- Retornaodo enabled para todos os bancos
      return enabled;
    },
  },
  {
    title: "Histórico de Alterações",
    icon: "mdi mdi-history",
    action: "log",
    colorClass: "text-primary",
    enabled: true,
    borderBottom: true,
    tip: "Histórico de alterações da proposta",
    order: 7,
    id: getIdCode(),
  },
  {
    disableUser: ["CLIENTE", "AGENTE", "OPERADOR PARCEIRO"],
    title: "Auditoria Bancária",
    icon: "mdi mdi-file-tree",
    action: "auditBanc",
    colorClass: "text-primary",
    enabled: true,
    tip: "Auditoria/Histórico de chamadas à API do banco",
    order: 11,
    id: getIdCode(),
  },
  {
    disableUser: ["AGENTE", "CLIENTE", "OPERADOR PARCEIRO", "OPERADOR"],
    title: "Voltar Status da Proposta",
    icon: "mdi mdi-arrange-send-backward",
    action: "voltarStatusProposta",
    colorClass: "text-primary",
    enabled: true,
    // tipoUsuario: ["administrador"],
    tip: "Volta o status da proposta para o status anterior",
    order: 12,
    id: getIdCode(),
    // enableFunction: (props, enabled) => {
    //   console.log("EnableFunction(voltar status): ", { enabled, props })
    //   console.log("EnableFunction(voltar status-1): ", props.user?.tipo?.toLowerCase())
    //   console.log("EnableFunction(voltar status-2): ", props.user?.tipo?.toLowerCase() !== 'administrador')
    //   return enabled
    //   // return (enabled && ['administrador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    // },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Integrar com Procrédito",
    icon: "mdi mdi-bank",
    action: "processarProcredito",
    colorClass: "text-primary",
    enabled: true,
    positions: ["portabilidade autorizada", "erro ao solicitar saldo"],
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    multSelect: true,
    tip: "Fazer integração da proposta com o sistema Procrédito",
    order: 13,
    id: getIdCode(),
    enableFunction: (props, enabled, filtros) => {
      let result = props?.multSelect?.length > 1;
      if (result) {
        const operationsEx = ["portabilidade"];
        const positions = ["pago"];
        return (
          positions.indexOf(filtros?.posicao?.toLowerCase()) >= 0 &&
          ["administrador", "operador"].indexOf(
            props?.user?.tipo?.toLowerCase()
          ) >= 0 &&
          operationsEx.indexOf(props?.operacao?.toLowerCase()) < 0
        );
      }
    },
  },
  {
    disableUser: ["AGENTE", "CLIENTE"],
    title: "Alterar Proposta",
    icon: "mdi mdi-update",
    action: "alteracaoProposta",
    colorClass: "text-primary",
    enabled: true,
    tip: "Alterar dados da proposta",
    order: 14,
    borderTop: true,
    tipoUsuarioEx: ["agente", "cliente", "operador parceiro"],
    positionsEx: ["pendente", "pago", "bloqueada", "negada"],
    id: getIdCode(),
    // enableFunction: (props, enabled) => {
    //   return (
    //     enabled &&
    //     (props.posicao.toLowerCase() != "aguardando refin" || ['administrador', 'operador'].indexOf(props.user?.tipo?.toLowerCase()) >= 0)
    //   );
    // },
  },
];

export const getTiposDocumentos = (user) => {
  let _tipos = [
    {
      key: 1,
      value: "d_pessoal",
      nome: "Documento Pessoal",
      anexavel: true,
    },
    {
      key: 2,
      value: "d_pessoalv",
      nome: "Documento Pessoal - Verso",
      anexavel: true,
    },
    {
      key: 3,
      value: "contracheque",
      nome: "ContraCheque",
      anexavel: true,
    },
    {
      key: 4,
      value: "c_bancario",
      nome: "Comprovante Bancário",
      anexavel: true,
    },
    {
      key: 5,
      value: "c_endereco",
      nome: "Comprovante de Endereço",
      anexavel: true,
    },
    {
      key: 7,
      value: "outros",
      nome: "Outros",
      anexavel: true,
    },
    {
      key: 8,
      value: "c_averbacao",
      nome: "Comprovante de Averbação",
      anexavel: true,
    },
    {
      key: 9,
      value: "ded",
      nome: "Documento de Evolução da Dívida",
      anexavel: true,
    },
    {
      key: 10,
      value: "ccb_assinada",
      nome: "CCB Assinada",
    },
    {
      key: 11,
      value: "doc_unificado",
      nome: "Documento Unificado para Assinatura",
    },
    {
      key: 12,
      value: "def_averbacao",
      nome: "Deferimento de Averbação",
      anexavel: true,
    },
    {
      key: 13,
      value: "ccb",
      nome: "CCB",
    },
    {
      key: 14,
      value: "ccb_port_assinada",
      nome: "CCB Port Assinada",
    },
    {
      key: 15,
      value: "ccb_refinport_assinada",
      nome: "CCB Refin Port Assinada",
    },
  ];

  if (["ADMINISTRADOR", "OPERADOR"].indexOf(user?.tipo) >= 0)
    _tipos.push({
      key: 6,
      value: "selfie",
      nome: "Selfie",
    });

  return _tipos.sort((a, b) => a.key - b.key);
};

export function handleGetUrlFromBase64(base64String, filename = "file") {
  const binaryString = window.atob(base64String);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: "application/pdf" }); // Supondo que é um PDF
  const url = URL.createObjectURL(blob);

  // Aqui você retorna não apenas a URL, mas também um nome para o arquivo
  return url;
}
