import React, { useState, useEffect } from "react";
import Table from "../../table.js";
import { post } from "axios";
import { sendData, getItemCrypt, bancoFornecedor } from "../../../helpers/auth";
import { Modal, Spinner } from "react-bootstrap";
import { Buttons } from "../../../components/layout";
import { uriList, TOKEN_KEY, TOKEN_CAPTCHA, TOKEN_BANK } from "../../../helpers/config";
import { getCookie, Aguarde, exportAsImage, } from "../../../helpers/general";
import { swalError } from "../../../components/swal";
import { connect } from "react-redux";
import { setAlterarProposta } from "../../../store/actions/dados.js";
import { temPermissao } from "../../../helpers/Uteis.js";

function Result({ ...props }) {
  const [result, setResult] = useState({});
  const [sending, setSending] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [negativo, setNegativo] = useState(false);
  const [_novaFormalizacao, setNovaFormalizacao] = useState(false);

  useEffect(() => {
    console.log("SimularPort.useEffect");
    async function getData() {
      await sendData({
        uri: uriList("simularPortabilidade"),
        content: { uuid: props.proposta },
        method: "POST",
      })
        .then((res) => {
          console.log("SimularPort.useEffect(res): ", res);
          if (res.status && parseInt(res.status) > 200) {
            setLoaded(true);
            setError(true);
            swalError({
              title: "Erro ao simular Portabilidade!",
              text: res.message,
            });
            // throw new Error();
            return;
          }
          if (res.error) {
            swalError({
              title: "Erro ao simular Portabilidade!",
              text: res.errorMessage,
            });

            if (res.novaFormalizacao && bancoFornecedor() != "inbursa")
              setNovaFormalizacao(true);
          }

          setNegativo(res && res.valor_liquido < 0);
          setResult(res);
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          setLoaded(true);
          setError(true);
          // swalError({ title: "Erro ao simular Portabilidade!" });
          swalError({
            title: "Erro ao simular Portabilidade!",
            text: JSON.stringify(error),
          });
        });
    }

    getData();
  }, []);

  async function sendToSign() {
    // let screenShot;
    // screenShot = await exportAsImage(
    //   "simulationPort-result",
    //   "simulationPortResult.jpg",
    //   props?.user?.name
    // );

    setSending(true);
    result["uuid"] = props.proposta;
    result["forca"] = true;
    await sendData({
      uri: uriList("assinarPortabilidade"),
      content: result,
      method: "POST",
    })
      .then(async (res) => {
        console.log("simularPort.sendToSign: ", res);
        if (res.status && parseInt(res.status) > 200) {
          swalError({
            title: "Erro ao enviar proposta para assinatura!",
            text: JSON.stringify(res.message || error),
          });
          setLoaded(true);
          setError(true);
          setSending(false);
          // await gravarScreenShot(screenShot, props?.proposta, props?.user?.name)
          return;
        }
        goBack();
      })
      .catch((error) => {
        setLoaded(true);
        setError(true);
        setSending(false);
        swalError({
          title: "Erro ao executar ação!",
          text: JSON.stringify(error.message || error),
        });
      });
  }

  async function solicitarPagtoSaldo() {
    setSending(true);
    result["uuid"] = props.proposta;
    result["forca"] = true;
    await sendData({
      uri: uriList("pagarPortabilidade"),
      content: result,
      method: "POST",
    })
      .then(async (res) => {
        console.log("simularPort.sendToSign: ", res);
        if (res.status && parseInt(res.status) > 200) {
          swalError({
            title: "Erro ao enviar proposta para assinatura!",
            text: JSON.stringify(res.message || error),
          });
          setLoaded(true);
          setError(true);
          setSending(false);
          // await gravarScreenShot(screenShot, props?.proposta, props?.user?.name)
          return;
        }
        goBack();
      })
      .catch((error) => {
        setLoaded(true);
        setError(true);
        setSending(false);
        swalError({
          title: "Erro ao executar ação!",
          text: JSON.stringify(error.message || error),
        });
      });
  }

  async function enivarParaConformidade() {
    setSending(true);
    result["uuid"] = props.proposta;
    result["forca"] = true;
    await sendData({
      uri: uriList("enviarParaConformidade"),
      content: result,
      method: "POST",
    })
      .then(async (res) => {
        console.log("simularPort.sendToSign: ", res);
        if (res.status && parseInt(res.status) > 200) {
          swalError({
            title: "Erro ao enviar proposta para conformidade!",
            text: JSON.stringify(res.message || error),
          });
          setLoaded(true);
          setError(true);
          setSending(false);
          // await gravarScreenShot(screenShot, props?.proposta, props?.user?.name)
          return;
        }
        goBack();
      })
      .catch((error) => {
        setLoaded(true);
        setError(true);
        setSending(false);
        swalError({
          title: "Erro ao executar ação!",
          text: JSON.stringify(error.message || error),
        });
      });
  }

  async function novaFormalizacao() {
    setSending(true);
    result["uuid"] = props.proposta;
    result["forca"] = true;
    await sendData({
      uri: uriList("novaFormalizacao"),
      content: result,
      method: "POST",
    })
      .then(async (res) => {
        console.log("simularPort.novaFormalizacao: ", res);
        if (res.status && parseInt(res.status) > 200) {
          swalError({
            title: "Erro ao fazer nova Formalização!",
            text: JSON.stringify(res.message || error),
          });
          setLoaded(true);
          setError(true);
          setSending(false);
          // await gravarScreenShot(screenShot, props?.proposta, props?.user?.name)
          return;
        }
        goBack();
      })
      .catch((error) => {
        setLoaded(true);
        setError(true);
        setSending(false);
        swalError({
          title: "Erro ao executar ação!",
          text: JSON.stringify(error.message || error),
        });
      });
  }

  const gravarScreenShot = async (screenShot, uuid) => {
    try {
      console.log("simlarPort.gravarScreenShot(1): ", screenShot);
      // console.log("simlarPort.gravarScreenShot(1): ", screenShot);
      let dObj = new FormData();
      let [latitude, longitude] = getCookie({ label: "geolocation" })
        ? getCookie({ label: "geolocation" }).split(",")
        : ["", ""];

      dObj.append("tipo", "simulacaoPort");
      dObj.append("proposta_uuid", uuid);
      dObj.append("file", screenShot);
      dObj.append("ip", getCookie({ label: "ip" }));
      dObj.append("geolocation", JSON.stringify({ latitude, longitude }));
      dObj.append("bancoFornecedor", bancoFornecedor())
      console.log("simlarPort.gravarScreenShot(2)");

      const url = uriList("documentos");
      const config = {
        headers: {
          Accept: "*",
          key: process.env.REACT_APP_KEY,
          "content-type": "multipart/form-data",
          "Authorization": `bearer ${getItemCrypt(TOKEN_KEY)}`,
          "captcha": getItemCrypt(TOKEN_CAPTCHA),
        },
      };

      console.log("simlarPort.gravarScreenShot(3)");
      await post(url, dObj, config)
        .then((response) => {
          console.log("simlarPort.gravarScreenShot(ok)");
        })
        .catch((err) => {
          console.log("simlarPort.gravarScreenShot(error)");
        });
      console.log("simlarPort.gravarScreenShot(4)");
    } catch (error) {
      console.log("simlarPort.gravarScreenShot(Error)");
    }
  };

  const Enviando = () => {
    return <Aguarde />;
  };

  function goBack() {
    setSending(false);
    setError(false);
    if (props.goBack) props.goBack();
    if (props.onCloseEv) props.onCloseEv();
  }

  function getSolicitarPagtoSaldo() {
    console.log("getSolicitarPagtoSaldo: ", props.detalhes?.conformidadeapv?.toString()?.toLowerCase(), props.detalhes?.posicao?.toLowerCase());
    const _posicoesProp = props.detalhes?.posicoes?.historico
    const _posicao = props.detalhes?.posicao?.toLowerCase()
    if (bancoFornecedor() == "brb")
      return _posicao != "aguardando pagamento" && loaded && !error && !sending && !negativo &&
        (props.detalhes?.conformidadeapv?.toString()?.toLowerCase() == "sim" && _posicoesProp?.indexOf("saldo formalizado") >= 0)
    else
      return _posicao != "aguardando pagamento" && loaded && !error && !sending && !negativo
  }
  function getEnviarConformidade() {
    console.log("getEnviarConformidade: ", props.detalhes?.conformidadeapv?.toString()?.toLowerCase(), props.detalhes?.posicao?.toLowerCase());
    const _posicao = props.detalhes?.posicao?.toLowerCase()
    const _posicoesProp = props.detalhes?.posicoes?.historico
    const _assinada = _posicoesProp?.some(posicao => ["proposta assinada", "proposta port assinada"].includes(posicao))
    console.log("getConformidade(posicoes): ", { _posicoesProp, _assinada });
    if (bancoFornecedor() == "brb")
      return ["aguardando pagamento", "analise esteira"].indexOf(_posicao) < 0 && loaded && !error && !sending && !negativo &&
        (props.detalhes?.conformidadeapv?.toString()?.toLowerCase() !== "sim") && _assinada
  }

  return (
    <Modal
      size={"lg"}
      scrollable
      show={true}
      animation={false}
      backdrop="static"
      centered
    >
      <Modal.Body
        style={{ width: "100%", justifyContent: "center", textAlign: "center" }}
      >
        <fieldset className="col-12" id="simulationPort-result">
          <h4
            className="display-4 text-center font-weight-bold"
            style={{ marginBottom: "15px", color: "#3E6C84" }}
          >
            Simulação de Portabilidade
          </h4>
          {sending && <Enviando />}

          {error && (
            <div className="text-center my-3">
              <span
                className="d-block lead font-weight-bold"
                style={{ color: "red" }}
              >
                Erro ao simular Portabilidade
              </span>
              <span className="d-block lead ">
                Possíveis erros: Saldo Devedor maior que o Valor da Proposta,
                API fora do ar, etc...
              </span>
              <br />
              <span className="d-block lead font-weight-bold">
                Caso ainda restem dúvidas, entre em contato com o suporte.
              </span>
            </div>
          )}

          {!error && !sending && !loaded && (
            <div className="text-center text-info my-3">
              <span className="d-block lead">
                Simulando portabilidade, aguarde...
              </span>
            </div>
          )}
          <>
            {!error && !sending && loaded && (
              <Table
                matrix={result}
                bordered={true}
                detailed={true}
                operacao={result.operacao}
                admin={true}
                simularPort={true}
                currency={getCookie({ label: "currency" })}
              />
            )}
            {props?.detalhes?.fluxo != "padrao" && !_novaFormalizacao && (!getSolicitarPagtoSaldo() && !getEnviarConformidade()) &&
              <Buttons
                first="Voltar"
                // second="Solicitar Pagto Saldo"
                goBack={goBack}
                disabledFirst={!loaded}
                disabledSecond={true}
              // submit={solicitarPagtoSaldo}
              />
            }
            {props?.detalhes?.fluxo != "padrao" && !_novaFormalizacao && getSolicitarPagtoSaldo() &&
              <Buttons
                first="Voltar"
                second="Solicitar Pagto Saldo"
                goBack={goBack}
                disabledFirst={!loaded}
                // disabledSecond={false}
                submit={solicitarPagtoSaldo}
              />
            }
            {props?.detalhes?.fluxo != "padrao" && !_novaFormalizacao && getEnviarConformidade() &&
              <Buttons
                first="Voltar"
                second="Conformidade"
                goBack={goBack}
                disabledFirst={!loaded}
                // disabledSecond={false}
                submit={enivarParaConformidade}
              />
            }
            {(props?.detalhes?.fluxo == "padrao" || _novaFormalizacao) &&
              <Buttons
                first="Voltar"
                second="Nova Formalização"
                goBack={goBack}
                disabledFirst={!loaded}
                disabledSecond={!loaded || sending || error || negativo}
                submit={novaFormalizacao}
              />
            }
          </>
        </fieldset>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    detalhes: state.dados.detalhes,
    proposta: state.dados.proposta,
  };
}

export default connect(mapStateToProps)(Result);
