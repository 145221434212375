import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import { uriList } from "../../../../helpers/config";
import { bancoFornecedor, sendData } from "../../../../helpers/auth";
import { bancos } from "../../../../helpers/bancos";
import { confirmSwal, swalError, swalMessage } from "../../../swal";
import { getCookie, Aguarde, getTiposDocumentos } from "../../../../helpers/general";
import { connect } from "react-redux";
import Bank from "../../simulator/bank";

const ReapresentarTED = ({ ...props }) => {
  const [warning, setWarning] = useState({ descricao: 0, observacao: 0 })
  const [gravando, setGravando] = useState(false);
  const [data, setData] = useState({
    banco: props?.item?.status == 'analise' ? props?.item?.banco || "" : '',
    agencia: props?.item?.status == 'analise' ? props?.item?.agencia || "" : '',
    conta: props?.item?.status == 'analise' ? props?.item?.conta || "" : '',
    t_conta: props?.item?.status == 'analise' ? props?.item.t_conta || "corrente" : '',
    ip: getCookie({ label: "ip" }),
  });
  const [proposta, setProposta] = useState(props.proposta);
  const _bancos = bancos();

  function handleClose(e, close) {
    if (e) e.preventDefault();

    if (props.onCloseEv)
      props.onCloseEv(close);
  }

  function validarDados() {
    let _warning = { banco: 0, agencia: 0, conta: 0 };
    if (!data.banco || data.banco === "") {
      _warning = { ..._warning, banco: 1 };
      return false;
    }
    if (!data.agencia || data.agencia === "") {
      _warning = { ..._warning, agencia: 1 };
      return false;
    }
    if (!data.conta || data.conta === "") {
      _warning = { ..._warning, conta: 1 };
      return false;
    }

    console.log("ReapresentarTED.validarDados(1): ", _warning);
    setWarning(_warning);

    return !_warning.banco && !_warning.agencia && !_warning.conta;
  }

  async function tedReapresentada() {
    let result = false

    //-- Checar se TED foi reapresentada
    await sendData({
      uri: uriList("tedreapresentada"),
      content: { uuid: props?.item?.proposta_uuid || props?.proposta },
      method: "POST",
    }).then((res) => {
      if (res.status === 200)
        result = true
    });

    return result;
  }

  async function handlePost(e) {
    async function callback(param) {
      console.log("ReapresentarTED.handlePost(0):", param);
      if (!param)
        return false

      e.preventDefault();
      console.log("ReapresentarTED.handlePost(1):", data);

      setGravando(true);
      const content = { ...data, uuid: proposta, }
      console.log("ReapresentarTED.handlePost(2): ", content);

      sendData({
        uri: uriList("reapresentarTED"),
        content,
        method: "POST",
      })
        .then((res) => {
          setGravando(false);
          if (res.status === 200) {
            swalMessage({ title: res?.message || "TED reapresentada com sucesso." });
            console.log("ReapresentarTED.handlePost(3)");
            handleClose();
          }
          else {
            swalError({ title: res?.message || "Erro ao reapresentar TED" });
          }
        })
        .catch((e) => {
          setGravando(false);
          swalError({ title: e?.message || "Erro ao reapresentar TED" });
          console.log("ReapresentarTED.handlePost(erro)");
          // console.log("Deu errado")
          console.log(e);
          return false;
        });
    }

    const _tedReapresentada = await tedReapresentada();

    console.log("ReapresentarTED.handlePost(0):", _tedReapresentada);

    if (validarDados()) {
      // if (_tedReapresentada)
      //   swalError({ title: "TED já reapresentada." });
      // else
      confirmSwal({ callback, title: "Confirma reapresentação da TED?" });
    }
    else
      swalError({ title: "Dados inválidos, favor preencher todos os campos." });
  }

  async function handleEnviarDados(e) {
    async function callback(param) {
      console.log("ReapresentarTED.handlePost(0):", param);
      if (!param)
        return false

      e.preventDefault();
      console.log("ReapresentarTED.handlePost(1):", data);

      setGravando(true);
      const content = { ...data, uuid: proposta, }
      console.log("ReapresentarTED.handlePost(2): ", content);

      sendData({
        uri: uriList("enviarDadosTED"),
        content,
        method: "POST",
      })
        .then((res) => {
          setGravando(false);
          if (res.status === 200) {
            swalMessage({ title: res?.message || "TED reapresentada com sucesso." });
            console.log("ReapresentarTED.handlePost(3)");
            handleClose();
          }
          else {
            swalError({ title: res?.message || "Erro ao reapresentar TED" });
          }
        })
        .catch((e) => {
          setGravando(false);
          swalError({ title: e?.message || "Erro ao reapresentar TED" });
          console.log("ReapresentarTED.handlePost(erro)");
          // console.log("Deu errado")
          console.log(e);
          return false;
        });
    }

    const _tedReapresentada = await tedReapresentada();
    // const _tedReapresentada = false

    console.log("ReapresentarTED.handlePost(0):", _tedReapresentada);

    if (validarDados()) {
      // if (_tedReapresentada)
      //   swalError({ title: "TED já reapresentada." });
      // else
      confirmSwal({ callback, title: "Confirma atualização de dados da TED?" });
    }
    else
      swalError({ title: "Dados inválidos, favor preencher todos os campos." });
  }

  async function handleCancel(e) {
    async function callback(param) {
      if (!param)
        return false

      await props.cancelEv();
      handleClose(e, !props?.cancelEv);
    }

    if (props.cancelEv)
      confirmSwal({ callback, title: `Confirma o cancelamento da Reapresentação?` })
    else
      handleClose(e, !props?.cancelEv);
  }

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("[REAPRESENTAR TED-ITEM]: ", name, value)
    // let _data = { ...data, [name]: value }; 

    let target = e.currentTarget;
    if (["t_conta", "banco"].indexOf(name) >= 0)
      setData({ ...data, [name]: value });
    if (["agencia"].indexOf(name) >= 0)
      setData({ ...data, [name]: value.replace(/[^0-9]/g, "") });
    else {
      if (bancoFornecedor() == "inbursa" && name == "conta")
        setData({ ...data, [name]: value });
      else
        setData({
          ...data, [name]: value.replace(/[^0-9.-]/g, ""),
        });
    }
  }

  useEffect(() => {
    console.log("[REAPRESENTAR TED-ITEM]: ", props?.item?.proposta_uuid || props?.item?.uuid);
    setProposta(props?.item?.proposta_uuid || props?.item?.uuid || props?.proposta);
  }, []);

  return (
    <>
      {gravando && <Aguarde legenda={"Aguarde, gravando dados..."} />}
      <Modal
        size="md"
        show={true}
        centered
        onHide={(e) => {
          handleClose(e, true);
        }}
        animation={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>Reapresentar TED</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="maxWidth650">
          <Bank
            data={data}
            handleChange={handleChange}
            warning={warning}
            nums={handleChange}
            alpha={handleChange}
            bancos={_bancos}
            interno
            focusBanco
            poupanca
          />
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
              {props?.item &&
                ["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 &&
                <Button color="danger" onClick={handleCancel}>
                  Cancelar
                </Button>}
            </div>
            <div>
              <Button color="info" onClick={handleEnviarDados} className="mr-2">
                Enviar Dados
              </Button>
              {["ADMINISTRADOR", "OPERADOR"].indexOf(props.user?.tipo) >= 0 &&
                <Button color="success" onClick={handlePost}>
                  Reapresentar
                </Button>
              }
              <Button
                color="secondary"
                style={{ marginLeft: "10px" }}
                onClick={(e) => {
                  handleClose(e, true);
                }}
              >
                Fechar
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    proposta: state.dados.proposta,
    detalhes: state.dados.detalhes,
    user: state.dados.user,
  };
}

export default connect(mapStateToProps)(ReapresentarTED);
